import React, { useEffect } from 'react';
import { orderAction, productAction } from 'redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRegister, OrderSummaryNew, ServiceCard } from 'components';
import { Col, message, Row } from 'antd';
import { calculateStuffInfo } from 'utils/utils';

const AdditionalServicesNew = ({ currentStep }) => {
  const dispatch = useDispatch();

  const { basketItems, products } = useSelector(store => store.product);
  const { selectedStuffs } = useSelector(store => store.stuff);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(productAction.getAll());
  }, []);

  function getStoreCount() {
    let total = 0;
    basketItems &&
      basketItems.map(item => item.product.productType == 'STORE' && (total = total + item?.count));
    return total;
  }

  const stuffListInfo = () => {
    return calculateStuffInfo(selectedStuffs);
  };

  const addProductToBasket = (product, count) => {
    if (basketItems.some(item => item.product.id === product.id)) {
      basketItems.map(item => {
        if (item.product.id === product.id) {
          item.count =
            item.product.productType === 'SHIPPING' ? 1 : count !== 0 ? count : item.count + 1;
          item.product.price = product.price;

          if (getStoreCount() < stuffListInfo().storeUnits && product.productType == 'STORE') {
            message.warning('Toplam hacim, seçmiş olduğunuz depo sayısına sığmamaktadır.');
          }

          dispatch(productAction.updateBasket(basketItems));
          dispatch(productAction.setBasketTotal(basketItems.reduce(sumTotal, 0)));
          return;
        }
      });
    } else {
      dispatch(productAction.updateBasket([...basketItems, { product, count: count + 1 }]));
      dispatch(
        productAction.setBasketTotal(
          [...basketItems, { product, count: count + 1 }].reduce(sumTotal, 0),
        ),
      );
    }
  };

  function sumTotal(accumulator, item) {
    return accumulator + item.product.price * item.count;
  }

  const removeProductFromBasket = product => {
    const filteredItems = basketItems.filter(item => item.product.id != product.id);

    if (getStoreCount < stuffListInfo().storeUnits) {
      message.warning('Toplam hacim, seçmiş olduğunuz depo sayısına sığmamaktadır.');
    }

    dispatch(productAction.updateBasket(filteredItems));
    dispatch(productAction.setBasketTotal(filteredItems.reduce(sumTotal, 0)));

    if (product.productType === 'SHIPPING') {
      dispatch(orderAction.setDistrict(null));
      dispatch(orderAction.setShippingDate(null));
    }
  };

  return (
    <>
      <Row type="flex" justify="center" gutter={[16, 16]}>
        {currentStep === 1 ? (
          <Col lg={15} md={24} align="center">
            <Col
              md={8}
              sm={24}
              style={{ borderRight: window.screen.width > 758 ? '1px solid #E8E8E8' : 'none' }}
            >
              <Col align="center">
                <h3 style={{ color: '#f27a1a' }}>Depolar</h3>
              </Col>
              {products.map(
                product =>
                  product.productType === 'STORE' && (
                    <Col align="center" key={product.id}>
                      <ServiceCard
                        product={product}
                        addProductToBasket={addProductToBasket}
                        removeProductFromBasket={removeProductFromBasket}
                      />
                    </Col>
                  ),
              )}
            </Col>

            <Col md={8} sm={24}>
              <Col align="center">
                <h3 style={{ color: '#f27a1a' }}>Nakliye</h3>
              </Col>
              {products.map(
                product =>
                  product.productType === 'SHIPPING' && (
                    <Col align="center" key={product.id}>
                      <ServiceCard
                        product={product}
                        addProductToBasket={addProductToBasket}
                        removeProductFromBasket={removeProductFromBasket}
                      />
                    </Col>
                  ),
              )}
            </Col>
            <Col
              md={8}
              sm={24}
              style={{ borderLeft: window.screen.width > 758 ? '1px solid #E8E8E8' : 'none' }}
            >
              <Col align="center">
                <h3 style={{ color: '#f27a1a' }}>Ürünler</h3>
              </Col>
              {products.map(
                product =>
                  product.productType === 'PRODUCT' && (
                    <Col align="center" key={product.id}>
                      <ServiceCard
                        product={product}
                        addProductToBasket={addProductToBasket}
                        removeProductFromBasket={removeProductFromBasket}
                      />
                    </Col>
                  ),
              )}
            </Col>
          </Col>
        ) : currentStep === 2 ? (
          <>
            <Col lg={15} md={24} align="center" style={{ marginTop: 8 }}>
              <Col span={24} align="center">
                <h3 style={{ color: '#f27a1a' }}>Müşteri Bilgileri</h3>
              </Col>
              <Col span={24} align="center">
                <CustomerRegister />
              </Col>
            </Col>
          </>
        ) : (
          <div></div>
        )}
        <Col
          lg={currentStep === 3 ? 24 : 9}
          md={24}
          style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}
        >
          <Col span={24} align="center">
            <h3 style={{ color: '#f27a1a' }}>Sepetiniz</h3>
          </Col>
          <Col span={24}>
            <OrderSummaryNew removeProductFromBasket={removeProductFromBasket} />
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default AdditionalServicesNew;
