import { combineReducers } from 'redux';
import auth from './auth/auth.reducer';
import customer from './customer/customer.reducer';
import order from './order/order.reducer';
import stuff from './stuff/stuff.reducer';
import payment from './payment/payment.reducer';
import portal from './portal/portal.reducer';
import product from './product/product.reducer';
import store from './store/store.reducer';
import shipping from './shipping/shipping.reducer';
import offer from './offer/offer.reducer';

const rootReducer = combineReducers({
  auth,
  customer,
  order,
  stuff,
  payment,
  portal,
  product,
  store,
  shipping,
  offer,
});

export default rootReducer;
