import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from 'redux/modules';
import { ExcelExport } from 'components';
import { Row, Col, Table, Input, Button, Tooltip, Icon, Popconfirm } from 'antd';
import AddProductModal from './AddProductModal';
import AddCustomerModal from './AddCustomerModal';
import AddDistrictModal from './AddDistrictModal';
import { AimOutlined } from '@ant-design/icons';
import { searchByTextInArray } from 'utils/utils';

const Product = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [filteredProducts, setfilteredProducts] = useState([]);
  const { products } = useSelector(state => state.product);

  useEffect(() => {
    dispatch(productAction.getAll());
  }, []);

  useEffect(() => {
    const filteredList = searchByTextInArray(products, searchKey);
    setfilteredProducts(filteredList);
  }, [searchKey]);

  const columns = [
    {
      title: 'Resim',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: '20%',
      render: imageUrl => <img src={imageUrl} alt="avatar" style={{ width: 75 }} />,
    },
    {
      title: 'Ürün Adı',
      dataIndex: 'name',
      key: 'name',
      exportToExcel: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      exportToExcel: true,
    },
    {
      title: 'Fiyat (₺)',
      key: 'price',
      align: 'center',
      exportToExcel: true,
      render: record => <div>{record.productType === 'SHIPPING' ? '-' : record.price}</div>,
    },
    {
      title: 'Ürün Tipi',
      dataIndex: 'productType',
      key: 'productType',
      align: 'center',
      exportToExcel: true,
      render: record => (
        <div>{record === 'PRODUCT' ? 'Malzeme' : record === 'STORE' ? 'Depo' : 'Hizmet'}</div>
      ),
    },
    {
      title: 'İşlem',
      key: 'edit',
      align: 'center',
      exportToExcel: false,
      render: record => (
        <div style={{ color: '#1890ff' }}>
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Col span={12}>
              <Tooltip title="Düzenle!" placement="top">
                <Icon
                  type="edit"
                  onClick={() => {
                    dispatch(productAction.handleVisibleProductModal(record));
                  }}
                />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Ürünü silmek istediğinizden emin misiniz?"
                onConfirm={() => {
                  dispatch(productAction.deleteProduct(record));
                }}
                onCancel={() => {}}
                okText="Evet"
                cancelText="Hayır"
              >
                <Tooltip title="Kaldır" placement="top">
                  <Icon type="delete" style={{ color: 'red', cursor: 'pointer' }} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row justify="center" gutter={[8, 16]} style={{ textAlign: 'center' }}>
        <Col sm={24} md={15}>
          <Input placeholder="Ara..." onChange={e => setSearchKey(e.target.value)} />
        </Col>
        <Col sm={8} md={3}>
          <Button
            type="primary"
            icon="plus-circle"
            onClick={() => dispatch(productAction.handleVisibleProductModal())}
            style={{ backgroundColor: '#f27a1a', color: '#fff', width: '100%' }}
          >
            Yeni
          </Button>
          <AddCustomerModal />
          <AddProductModal />
        </Col>
        <Col sm={8} md={3}>
          <Button
            type="primary"
            onClick={() => dispatch(productAction.handleVisibleDistrictModal())}
            style={{ backgroundColor: '#f27a1a', color: '#fff', width: '100%' }}
          >
            <AimOutlined /> İlçe Ekle
          </Button>
          <AddCustomerModal />
          <AddDistrictModal />
        </Col>

        <Col sm={8} md={3}>
          <ExcelExport dataSource={products} columns={columns} name={'Ürün'} />
        </Col>
        <Col span={24}>
          <Table
            size={'small'}
            dataSource={
              searchKey == '' && filteredProducts.length < 1 ? products : filteredProducts
            }
            pagination={{ pageSize: 10 }}
            columns={columns}
            rowKey={(record, index) => index}
          />
        </Col>
      </Row>
    </>
  );
};

export default Product;
