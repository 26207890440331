import React from 'react';
import { Button } from 'antd';
import ReactExport from 'react-export-excel';

const ExcelExportCustomers = customers => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <>
      <ExcelFile
        filename={'MusteriListesi'}
        element={
          <Button
            type="primary"
            icon="file-excel"
            style={{ backgroundColor: '#f27a1a', color: '#fff', width: '100%' }}
          >
            Excel
          </Button>
        }
      >
        <ExcelSheet data={customers.customers} name="customers">
          <ExcelColumn label="Adı Soyadı" value={'fullName'} />
          <ExcelColumn label="Email" value={'email'} />
          <ExcelColumn label="Telefon" value={'phone'} />
          <ExcelColumn label="Adres" value={'address'} />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ExcelExportCustomers;
