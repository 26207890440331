export const PROCEED_PAYMENT = 'PROCEED_PAYMENT';
export const PROCEED_PAYMENT_FULFILLED = 'PROCEED_PAYMENT_FULFILLED';
export const PROCEED_PAYMENT_REJECTED = 'PROCEED_PAYMENT_REJECTED';

export const GET_TRANSACTION_BY_CUSTOMER_PHONE = 'GET_TRANSACTION_BY_CUSTOMER_PHONE';
export const GET_TRANSACTION_BY_CUSTOMER_PHONE_FULFILLED =
  'GET_TRANSACTION_BY_CUSTOMER_PHONE_FULFILLED';
export const GET_TRANSACTION_BY_CUSTOMER_PHONE_REJECTED =
  'GET_TRANSACTION_BY_CUSTOMER_PHONE_REJECTED';

export const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE';
