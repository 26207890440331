import React from 'react';
import ModalImage from 'react-modal-image';
import { SearchOutlined } from '@ant-design/icons';

const eCommerce = () => {
  return (
    <div data-smooth-scroll-offset="77" style={{ marginTop: '100px' }}>
      <a id="start"></a>
      <div className="main-container">
        <div className="container">
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ color: '#f27a1a' }}>E-Ticaret Depo ve Ofis Hizmeti</h2>
          </div>
        </div>
        <section className="feature-large switchable bg--white custom-warehouse-info">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-lg-6 col-md-6" style={{ textAlign: 'center' }}>
                <ModalImage
                  small={'img/gallery-images/store6.jpg'}
                  large={'img/gallery-images/store6.jpg'}
                  alt="meeting"
                />
                {/* <SearchOutlined /> Tam Ekran Görebilmek için Resime Tıklayın. */}
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="text-block">
                  <div className="row">
                    <div className="text-block">
                      <p style={{ fontSize: 13, padding: 15 }}>
                        Çağımızın ihtiyaçlarına göre ticaretinizi ister geleneksel yollarla ister
                        dijital ortamda yapın, deponuz ve deponuzun yanı başında ofisiniz DepoPro’da
                        hazır.
                      </p>
                      <p style={{ fontSize: 13, padding: 15 }}>
                        Hem ofis hem toplantı odası olarak kullanabileceğiniz şekilde tasarlanan
                        “DepoPro E-Ticaret” alanında ticaretinize hız katacak her türlü detay hazır
                        olarak sizleri bekliyor. E-Ticaret Ofis’inizi deponuzla birlikte avantajlı
                        fırsatlarla kiralamak için bize ulaşın.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="container">
            <div
              className="row"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/toplanti_odasi.png" style={{ maxWidth: '30%' }} />
                  <h4>Ofis Toplantı Odası</h4>
                  <p>Hem ofis hem toplantı odası kullanımı için ortak tasarlanan ofisler.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/paketleme_alani.png" style={{ maxWidth: '30%' }} />
                  <h4>Paketleme Alanı</h4>
                  <p>
                    Ürünlerinizi hazırlamanız için ofis alanı içinde paketleme için ayrılmış
                    alanlar.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/kargo_ulasimi.png" style={{ maxWidth: '30%' }} />
                  <h4>Kargo Ulaşımı</h4>
                  <p>Anlaşmalı kargo firmasıyla kolay kargo hizmeti.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/wifi.png" style={{ maxWidth: '30%' }} />
                  <h4>İnternet Erişimi</h4>
                  <p> Kesintisiz WiFi internet erişimi.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/printer.png" style={{ maxWidth: '30%' }} />
                  <h4>Ortak Kullanım Yazıcı</h4>
                  <p>...</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="feature feature-3 boxed boxed--lg boxed--border text-center">
                  <img alt="background" src="img/thermometer.png" style={{ maxWidth: '30%' }} />
                  <h4>Her Ofiste Bağımsız Klima</h4>
                  <p> ...</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default eCommerce;
