import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Row, Button, message } from 'antd';
import { offerAction } from 'redux/modules';
import InputMask from 'react-input-mask';
import { emailPatternValidate } from 'utils/utils';

const ContactForm = props => {
  const dispatch = useDispatch();
  const {
    form: { validateFields, getFieldDecorator, resetFields },
  } = props;

  return (
    <section
      className="switchable switchable--switch bg--secondary space--sm parallax"
      id="mail"
      data-overlay="8"
    >
      <div className="background-image-holder">
        <img alt="background" src="img/banner3.jpg" />
      </div>

      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-6 col-lg-5">
            <div className="switchable__text">
              <p className="lead" style={{ fontSize: '33px' }}>
                Size Ulaşalım
              </p>
              <p className="lead">
                Merak ettiğiniz her şey için size ücretsiz destek verecek DepoPro Danışmanımız ile{' '}
                <a style={{ color: 'white' }} href="https://wa.me/905374759733">
                  Kurumsal Whatsapp
                </a>{' '}
                hattımızdan hemen iletişime geçebilirsiniz.{' '}
                <a href="https://wa.me/905374759733">Bir tıklama uzağınızda.</a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg--secondary boxed boxed--border boxed--lg">
              <Form
                onSubmit={event => {
                  event.preventDefault();
                  validateFields((err, values) => {
                    if (!err) {
                      values = {
                        ...values,
                        phone: values.phone.split('-').join(''),
                      };
                      dispatch(offerAction.create({ ...values }));
                      resetFields();
                    } else {
                      message.error('Lütfen Verilerinizi Kontrol Edin.');
                    }
                  });
                }}
              >
                <Row gutter={24}>
                  <Col xs={24}>
                    <Form.Item>
                      {getFieldDecorator('fullName', {
                        rules: [{ required: true, message: 'Lütfen Ad ve Soyad Giriniz !' }],
                      })(<Input placeholder="Adınız Soyadınız" tooltiptitle="Adınız Soyadınız" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        rules: [
                          { required: true, message: 'Lütfen E-mail Adresinizi Giriniz !' },
                          { type: 'email', message: 'E-mail adresi girmelisiniz !' },
                          {
                            validator: (_, value) => emailPatternValidate(value),
                            message: 'E-mail adresi geçersiz !',
                          },
                        ],
                      })(<Input placeholder="E-mail" tooltiptitle="E-mail adresiniz" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item>
                      {getFieldDecorator('phone', {
                        rules: [{ required: true, message: 'Lütfen Telefon Numaranızı Giriniz!' }],
                      })(
                        <InputMask mask="0(999)-999-9999">
                          {() => <Input placeholder="Telefon" tooltiptitle="Telefon Numaranız" />}
                        </InputMask>,
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          backgroundColor: '#f27a1a',
                          color: '#fff',
                          border: 'none',
                        }}
                      >
                        Gönder
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <a id="warehouses" className="in-page-link"></a>
    </section>
  );
};

export default Form.create({ name: 'ContactForm' })(ContactForm);
