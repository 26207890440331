import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  InputNumber,
  Table,
} from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerAction, stuffAction, portalAction } from '../../redux/modules';

const { TextArea } = Input;
const { Option } = Select;

const LeasingForm = props => {
  const {
    form: { validateFields, getFieldDecorator, resetFields, setFieldsValue },
  } = props;

  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState([]);

  const selectedStuffs = useSelector(store => store.stuff.selectedStuffs);
  const storeList = useSelector(({ store }) => store.storeList);
  const selectedStores = useSelector(({ portal }) => portal.selectedStoresForLeasingForm);
  const filteredStoreList = useMemo(() => storeList.filter(item => item.availability), [storeList]);

  useEffect(() => {
    dispatch(customerAction.getAll());
  }, [dispatch]);

  const customers = useSelector(({ customer }) => {
    return customer.customers ? customer.customers.responseBody : [];
  });

  useEffect(() => {
    setFiltered(customers);
  }, [customers]);

  useEffect(() => {
    setFieldsValue({ stores: selectedStores });
    return () => {};
  }, [selectedStores, setFieldsValue]);

  const handleFilterData = inputValue => {
    const filteredData = inputValue
      ? customers.filter(
          item =>
            (item.phone + ' - ' + item.name + ' ' + item.surname + ' - ' + item.email)
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1 ||
            item.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
            item.surname.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
            item.email.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
            item.phone.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
        )
      : customers;
    setFiltered(filteredData);
  };

  const columns = [
    {
      title: 'Ürün',
      dataIndex: 'name',
      key: 'name',
      render: text => <b>{text}</b>,
    },
    {
      title: 'Adet',
      dataIndex: 'furnitureUnits',
      key: 'furnitureUnits',
    },
    {
      title: 'Ölçüler (cm)',
      render: record => record.width + '-' + record.height + '-' + record.depth,
    },
    {
      title: (
        <>
          Ürün (m<sup>3</sup>)
        </>
      ),
      render: record => (
        <>
          {(record.width * record.height * record.depth) / 1000000}m<sup>3</sup>
        </>
      ),
    },
    {
      title: 'Kaldır',
      render: record => (
        <Icon
          onClick={() => {
            const filteredSelectedStuffs = selectedStuffs.filter(
              selected => selected.uuid !== record.uuid,
            );
            dispatch(stuffAction.listSelectedStuffs(filteredSelectedStuffs));
          }}
          type="delete"
        />
      ),
    },
  ];

  return (
    <Row type="flex" justify="center">
      <Col xs={24} lg={24} xl={24}>
        <Card style={{ borderRadius: 10 }}>
          <Form
            onSubmit={event => {
              event.preventDefault();
              validateFields((err, values) => {
                if (!err) {
                  let splittedCustomerData = values.customer.split(' - ');
                  let stores = [];

                  const customer = {
                    ...customers.find(item => item.email === splittedCustomerData[2]),
                  };

                  values.stores.map(store =>
                    stores.push({
                      ...storeList.find(item => item.storeNo === store),
                      contractStartDate: values.contractStartDate,
                      contractEndDate: values.contractEndDate,
                      customer: customer,
                      stuffList: selectedStuffs,
                    }),
                  );

                  const transaction = {
                    ...values,
                    stores: stores,
                    customer: customer,
                    notes: [{ note: values.note }],
                  };
                  if (Object.entries(transaction.customer).length !== 0) {
                    dispatch(portalAction.createTransaction(transaction));
                    dispatch(stuffAction.listSelectedStuffs([]));
                    resetFields();
                  } else {
                    message.error('Lütfen Müşteri Seçiniz.');
                  }
                } else {
                  message.error(
                    'Kiralama İşlemi Gerçekleştirilemedi. Lütfen Bilgilerinizi Kontrol Edin.',
                  );
                }
              });
            }}
          >
            <Form.Item>
              {getFieldDecorator('customer', {
                rules: [{ required: true, message: 'Müşteri seçiniz' }],
              })(
                <AutoComplete
                  dataSource={
                    filtered &&
                    filtered.map(
                      item =>
                        item.phone + ' - ' + item.name + ' ' + item.surname + ' - ' + item.email,
                    )
                  }
                  placeholder="Müşteri Seçiniz"
                  onChange={e => handleFilterData(e)}
                  allowClear={true}
                />,
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('stores', {
                rules: [{ required: true, message: 'Depo seçiniz' }],
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Depo Seçiniz"
                  onChange={value => dispatch(portalAction.setSelectedStoresForLeasingForm(value))}
                  filterOption={(inputValue, option) => {
                    const splittedData = option.props.children.split('-').join('');
                    return (
                      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                        -1 || splittedData.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                >
                  {filteredStoreList.map(item => (
                    <Option key={item.storeNo} value={item.storeNo}>
                      {item.storeNo}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Row>
              <Col xs={8}>
                <Form.Item>
                  {getFieldDecorator('contractStartDate', {
                    rules: [{ required: true, message: 'Başlangıç tarihi seçiniz' }],
                  })(
                    <DatePicker
                      style={{ width: '100%', paddingRight: 5 }}
                      placeholder="Kira Başlangıç"
                      locale={locale}
                      format={'L'}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item>
                  {getFieldDecorator('contractEndDate', {
                    rules: [{ required: true, message: 'Bitiş tarihi seçiniz' }],
                  })(
                    <DatePicker
                      style={{ width: '100%', paddingLeft: 5 }}
                      placeholder="Kira Bitiş"
                      locale={locale}
                      format={'L'}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item>
                  {getFieldDecorator('paymentEndDate', {
                    rules: [{ required: true, message: 'Ödeme Bitiş Tarihi Seçiniz' }],
                  })(
                    <DatePicker
                      style={{ width: '100%', paddingLeft: 5 }}
                      placeholder="Ödeme Bitiş"
                      locale={locale}
                      format={'L'}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              {getFieldDecorator(
                'selectedProductList',
                {},
              )(
                <Table
                  size={'small'}
                  dataSource={selectedStuffs}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={false}
                  scroll={{ x: true }}
                />,
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Aylık Tutarı Giriniz' }],
              })(<InputNumber style={{ width: '100%' }} placeholder="Tutar" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('note', {
                initialValue: '',
              })(<TextArea placeholder="Notlar" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('paid', {
                valuePropName: 'checked',
                initialValue: false,
              })(<Checkbox>Tamamı Ödendi</Checkbox>)}
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: '#f27a1a',
                  color: '#fff',
                  border: 'none',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '30%',
                }}
              >
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Form.create({ name: 'leasing_form' })(LeasingForm);
