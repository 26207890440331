import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from 'redux/modules';

const StoreTypes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products } = useSelector(state => state.product);

  useEffect(() => {
    dispatch(productAction.getAll());
  }, []);

  return (
    <>
      <a id="warehousesTitle" className="in-page-link"></a>
      <section className="text-center space--sm">
        <div className="container">
          <div className="row text-block">
            <div className="col-md-12">
              <h2 className="primaryColor">Depo Çeşitleri</h2>
              <br />
            </div>
          </div>
          <div
            className="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {products.map(
              (product, index) =>
                product.productType === 'STORE' && (
                  <div className="col-lg-6 col-md-12" key={index}>
                    <a
                      onClick={() =>
                        history.push({
                          pathname: '/stores',
                          product,
                        })
                      }
                      style={{ color: '#666' }}
                    >
                      <div className="feature feature-3 boxed boxed--lg boxed--border">
                        <img
                          src={product.imageUrl}
                          style={{ cursor: 'pointer', color: '#595959', maxWidth: '100%' }}
                        />
                        <h4 className="primaryColor" style={{ cursor: 'pointer' }}>
                          {product.name}
                        </h4>

                        <p>
                          <br />
                          <br />
                          {Number(product.width).toFixed(2)} x {Number(product.height).toFixed(2)} x{' '}
                          {Number(product.depth).toFixed(2)}
                          <br />
                          (G x D x Y)
                          <br />
                          {(
                            Number(product.width) *
                            Number(product.height) *
                            Number(product.depth)
                          ).toFixed(2)}{' '}
                          m<sup>3</sup>
                          <br />
                        </p>
                        <h4 style={{ color: '#f27a1a' }}>
                          <div>{`${product.price} TL + KDV`}</div>
                        </h4>
                        <br />
                        <span className="btn__text">DETAY</span>
                      </div>
                    </a>
                  </div>
                ),
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default StoreTypes;
