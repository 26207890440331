import React, { useEffect } from 'react';
import { paymentAction } from 'redux/modules';
import { useDispatch } from 'react-redux';
import { Result, Button } from 'antd';

const Error = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentAction.clearLocalStorage());
  }, []);

  return (
    <>
      <section style={{ width: '100%', height: '100%', display: 'table', textAlign: 'center' }}>
        <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
          <Result
            status="error"
            icon={<img src={'img/error.svg'} />}
            title={
              <div style={{ color: '#ED1C24', fontSize: 26, fontWeight: 'bold' }}>
                Ödeme İşleminiz Başarısız Oldu
              </div>
            }
            subTitle={
              <div>Şu An İşleminizi Gerçekleştiremiyoruz, Lütfen Daha Sonra Tekrar Deneyiniz.</div>
            }
            extra={
              <Button
                style={{ backgroundColor: '#f27a1a', color: '#fff', border: 'none' }}
                href={'http://www.depopro.com.tr'}
                type="primary"
              >
                Ana Sayfa
              </Button>
            }
          />
        </div>
      </section>
    </>
  );
};

export default Error;
