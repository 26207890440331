import { message } from 'antd';
import { API } from 'config/configureAxios';
import * as customerTypes from './customer.type';

const REQUEST_URL = '/customer';

export const getAll = () => async dispatch => {
  dispatch({ type: customerTypes.LIST_CUSTOMER_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);
    dispatch({
      type: customerTypes.LIST_CUSTOMER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: customerTypes.LIST_CUSTOMER_REJECTED,
      payload: error,
    });
  }
};

export const create = data => async dispatch => {
  dispatch({ type: customerTypes.CREATE_CUSTOMER_PENDING });

  try {
    const response = await API.post(`${REQUEST_URL}`, data);
    dispatch({
      type: customerTypes.CREATE_CUSTOMER_FULFILLED,
      payload: response.data.responseBody,
    });
    if (response.data.responseCode === 200) {
      dispatch(getAll());
      dispatch(handleVisible());
      message.success('Müşteri başarıyla kaydedildi.');
    }
    if (response.data.responseCode === 409) {
      message.error(response.data.responseMessage);
    }
    return response;
  } catch (error) {
    dispatch({
      type: customerTypes.CREATE_CUSTOMER_REJECTED,
      payload: error,
    });
    message.error('Müşteri kaydetme işlemi gerçekleştirilemedi.');
    return error;
  }
};

export const update = data => async dispatch => {
  dispatch({ type: customerTypes.UPDATE_CUSTOMER_PENDING });

  try {
    const response = await API.put(`${REQUEST_URL}`, data);

    dispatch({
      type: customerTypes.UPDATE_CUSTOMER_FULFILLED,
      payload: response.data.responseBody,
    });

    if (response.data.responseCode === 200) {
      dispatch(getAll());
      dispatch(handleVisible());
      message.success('Müşteri Başarıyla Güncellendi.');
    }
    if (response.data.responseCode === 409) {
      message.error(response.data.responseMessage);
    }
  } catch (error) {
    dispatch({
      type: customerTypes.UPDATE_CUSTOMER_REJECTED,
      payload: error,
    });
    message.error('Müşteri güncelleme işlemi gerçekleştirilemedi.');
  }
};

export const deleteCustomer = data => async dispatch => {
  dispatch({ type: customerTypes.DELETE_CUSTOMER_PENDING });

  try {
    const response = await API.delete(`${REQUEST_URL}/` + data.id);

    dispatch({
      type: customerTypes.DELETE_CUSTOMER_FULFILLED,
      payload: response.data,
    });

    if (response.data.responseCode === 200) {
      dispatch(getAll());
      message.success('Müşteri Başarıyla Silindi.');
    } else {
      response.data &&
        message.error(response.data.responseMessage && response.data.responseMessage);
    }
  } catch (error) {
    dispatch({
      type: customerTypes.DELETE_CUSTOMER_REJECTED,
      payload: error,
    });
    message.error('Müşteri silme işlemi gerçekleştirilemedi.');
  }
};

export const handleVisible = data => dispatch => {
  dispatch({
    type: customerTypes.HANDLE_CUSTOMER_ADD_MODAL_VISIBLE,
    payload: data,
  });
};

export const setCurrentCustomer = data => dispatch => {
  dispatch({
    type: customerTypes.SET_CURRENT_CUSTOMER,
    payload: data,
  });
};
