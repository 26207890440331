export const LIST_OFFER_PENDING = 'LIST_OFFER_PENDING';
export const LIST_OFFER_FULFILLED = 'LIST_OFFER_FULFILLED';
export const LIST_OFFER_REJECTED = 'LIST_OFFER_REJECTED';

export const GET_OFFER_PENDING = 'GET_OFFER_PENDING';
export const GET_OFFER_FULFILLED = 'GET_OFFER_FULFILLED';
export const GET_OFFER_REJECTED = 'GET_OFFER_REJECTED';

export const CREATE_OFFER_PENDING = 'CREATE_OFFER_PENDING';
export const CREATE_OFFER_FULFILLED = 'CREATE_OFFER_FULFILLED';
export const CREATE_OFFER_REJECTED = 'CREATE_OFFER_REJECTED';

export const UPDATE_OFFER_PENDING = 'UPDATE_OFFER_PENDING';
export const UPDATE_OFFER_FULFILLED = 'UPDATE_OFFER_FULFILLED';
export const UPDATE_OFFER_REJECTED = 'UPDATE_OFFER_REJECTED';

export const DELETE_OFFER_PENDING = 'DELETE_OFFER_PENDING';
export const DELETE_OFFER_FULFILLED = 'DELETE_OFFER_FULFILLED';
export const DELETE_OFFER_REJECTED = 'DELETE_OFFER_REJECTED';

export const HANDLE_OFFER_ADD_MODAL_VISIBLE = 'HANDLE_OFFER_ADD_MODAL_VISIBLE';
