import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Empty, Row, Table, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

const OrderSummaryNew = ({ removeProductFromBasket }) => {
  const { basketItems, total } = useSelector(store => store.product);
  const { district, shippingDate } = useSelector(store => store.order);

  let monthlyAmount = 0;
  basketItems.map(item => {
    if (item.product.productType === 'STORE') {
      monthlyAmount += item.product.price * item.count;
    }
  });

  const columns = [
    {
      title: 'Ürün',
      dataIndex: 'product.name',
      key: 'product.name',
      width: '20%',
    },
    {
      title: 'Adet',
      align: 'center',
      key: 'count',
      width: '30%',
      render: item => (
        <b>
          {item?.product?.productType !== 'SHIPPING' ? (
            item.count
          ) : (
            <>
              {district}
              <br /> {moment(shippingDate).format('ll')}
            </>
          )}
        </b>
      ),
    },
    {
      title: 'Fiyat (₺)',
      key: 'product.price',
      align: 'center',
      render: record => (
        <div>
          {record?.product?.productType === 'SHIPPING'
            ? record.product.price.toFixed(2)
            : (record.product.price * record.count).toFixed(2)}
        </div>
      ),
    },
    {
      title: (
        <p>
          Hacim (m<sup>3</sup>)
        </p>
      ),
      align: 'center',
      render: record => (
        <div>
          <div>{record?.product?.productType === 'STORE' ? record?.count * 9 : '-'}</div>
        </div>
      ),
    },
    {
      title: 'Kaldır',
      key: 'delete',
      align: 'center',
      render: item => (
        <a key="list-loadmore-edit" style={{ marginTop: -20 }}>
          <Tooltip title="Kaldır" placement="top" style={{ marginTop: -20 }}>
            <CloseOutlined onClick={() => removeProductFromBasket(item.product)} />
          </Tooltip>
        </a>
      ),
    },
  ];

  return (
    <>
      <div style={{ padding: '0px !important' }}>
        {basketItems?.length < 1 ? (
          <Empty description="Sepetinizde Ürün Bulunmamaktadır." style={{ padding: '15px' }} />
        ) : (
          <div
            style={{
              overflowX: 'auto',
              overflow: 'scroll',
              display: 'block',
            }}
          >
            <Table
              bordered
              style={{
                minWidth: 400,
                borderRadius: '10px',
              }}
              dataSource={basketItems}
              columns={columns}
              size={'small'}
              tableLayout="fixed"
              pagination={false}
              rowKey={record => record?.product?.id}
            />
          </div>
        )}
      </div>
      <Card style={{ borderRadius: '10px', marginTop: '24px', marginBottom: '40px' }}>
        <Row>
          <Col span={12}>
            <b>Ara Toplam: </b>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <b>{total?.toFixed(2)} TL</b>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>KDV (%18): </b>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <b>{(total * 0.18).toFixed(2)} TL</b>
          </Col>
        </Row>

        <hr style={{ marginTop: '25px' }} />
        <Row>
          <Col span={12}>
            <b>Toplam: </b>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <b>{(total + total * 0.18).toFixed(2)} TL</b>
          </Col>
        </Row>
        <hr style={{ marginTop: '25px' }} />

        <Row>
          <Col span={24}>
            <b>
              * <span style={{ fontWeight: 'bold' }}>Not:</span> Sepetinizde bulunan Standart Depo
              için her ay {`${(monthlyAmount * 1.18).toFixed(2)}`} TL tutar, depo kirası olarak
              tahsil edilecektir.
            </b>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default OrderSummaryNew;
