import React from 'react';
import { storeAction } from 'redux/modules';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { Badge, Button, Descriptions, Modal, Popconfirm, message, Table } from 'antd';

const DepotModal = ({ visible, closeModal, data, ...otherProps }) => {
  const dispatch = useDispatch();
  let remainingTime = null;
  if (data) {
    const endDateDiffFromNowAsDay = DateTime.fromISO(data.contractEndDate)
      .diffNow()
      .as('day');

    remainingTime =
      endDateDiffFromNowAsDay > 0 ? `${Math.round(endDateDiffFromNowAsDay)} gün` : '-';
  }

  const stuffListColumns = [
    {
      title: 'Eşya Adı',
      dataIndex: 'name',
    },
    {
      title: 'Adet',
      dataIndex: 'furnitureUnits',
    },
    {
      title: 'Genişlik',
      dataIndex: 'width',
    },
    {
      title: 'Yükseklik',
      dataIndex: 'height',
    },
    {
      title: 'Derinlik',
      dataIndex: 'depth',
    },
  ];

  return (
    <Modal
      title={
        <>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div style={{ position: 'absolute', marginRight: 'auto', marginLeft: 'auto' }}>
              {data && 'No : ' + data.storeNo}
            </div>
            <Badge
              color={data && data.customer ? 'gold' : '#000'}
              text={data && data.customer ? 'Dolu' : 'Boş'}
            />
          </div>
        </>
      }
      visible={visible}
      onCancel={closeModal}
      centered={true}
      footer={null}
      width="50%"
    >
      <Popconfirm
        title="Depoyu Boşaltmak İstediğinize Emin Misiniz?"
        // disabled={data && data?.customer ? true : false}
        okText="Evet"
        cancelText="İptal"
        onConfirm={() => {
          data?.customer
            ? dispatch(storeAction.unloadStore(data.id))
            : message.warning('Depo Zaten Boş Durumda!');
          closeModal();
        }}
      >
        <Button
          type="primary"
          // disabled={data && data.customer ? false : true}
          style={{
            backgroundColor: data && !data.customer ? '#CCC' : '#f27a1a',
            color: '#000',
            border: 'none',
            position: 'absolute',
            right: '2%',
          }}
          icon="minus-circle"
        >
          Depoyu Boşalt
        </Button>
      </Popconfirm>

      {data && (
        <Descriptions title="Depo Bilgisi" bordered layout="vertical">
          <Descriptions.Item label="Müşteri Ad Soyad">
            {data.customer ? `${data.customer.name} ${data.customer.surname}` : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Müşteri E-Posta">
            {data.customer ? data.customer.email : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Müşteri Telefon Numarası">
            {data.customer ? data.customer.phone : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="Sözleşme Başlangıç">
            {data.contractStartDate
              ? DateTime.fromISO(data.contractStartDate).toFormat('dd-MM-yyyy  HH:MM')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Sözleşme Bitiş">
            {data.contractEndDate
              ? DateTime.fromISO(data.contractEndDate).toFormat('dd-MM-yyyy  HH:MM')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Kalan Süre">{remainingTime}</Descriptions.Item>

          <Descriptions.Item label="Eşya Listesi">
            <Table
              size={'small'}
              columns={stuffListColumns}
              dataSource={data.stuffList}
              pagination={false}
              rowKey={record => record.id}
            />
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};

export default DepotModal;
