import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stuffAction } from 'redux/modules';
import { Button, Card, Col, InputNumber, message, Row, Select, Tooltip, Typography } from 'antd';
import furnitures from 'constants/furnitures';

const { Option } = Select;
const uuidv4 = require('uuid/v4');

const FurnitureDetail = () => {
  const dispatch = useDispatch();

  const selectedStuffs = useSelector(store => store.stuff.selectedStuffs);

  const [currentFurniture, setCurrentFurniture] = useState(furnitures[0]);
  const [width, setWidth] = useState(currentFurniture.width);
  const [height, setHeight] = useState(currentFurniture.height);
  const [depth, setDepth] = useState(currentFurniture.depth);
  const [furnitureUnits, setFurnitureUnits] = useState(1);

  useEffect(() => {
    const { width, height, depth } = currentFurniture;
    setWidth(width);
    setHeight(height);
    setDepth(depth);
    return () => {};
  }, [currentFurniture]);

  const addToSelectedStuffs = () => {
    const furniture = {
      ...currentFurniture,
      width,
      height,
      depth,
      furnitureUnits: furnitureUnits,
      uuid: { uuidv4 },
    };
    const selected = [furniture, ...selectedStuffs];
    dispatch(stuffAction.listSelectedStuffs(selected));
  };

  return (
    <>
      <Row type="flex" justify="center" style={{ height: '80%' }}>
        {currentFurniture && (
          <Card
            style={{ borderRadius: 10 }}
            cover={
              <>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <b>Eşya Seçiniz:</b>
                  </Col>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Select
                      showSearch
                      style={{ width: '60%', margin: '15px 0px 15px 0px' }}
                      placeholder="Eşya Seç"
                      defaultValue={currentFurniture.name}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toLocaleUpperCase('tr')
                          .indexOf(inputValue.toLocaleUpperCase('tr')) !== -1
                      }
                      onSelect={val => {
                        setCurrentFurniture(furnitures.find(furniture => furniture.name === val));
                      }}
                    >
                      {furnitures.map(furniture => (
                        <Option key={(item, index) => index} value={furniture.name}>
                          {furniture.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <div className="line1" /* style={{ width: currentFurniture.cssWidth }} */>
                  {height} cm
                </div>
                <div className="line2">{width} cm</div>
                <div className="line3">{depth} cm</div>
                <img
                  style={{
                    width: '90%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  alt={currentFurniture.name}
                  src={currentFurniture.photo}
                />
              </>
            }
          >
            <Row type="flex" justify="center" gutter={[48, 48]}>
              <Col>
                <Row type="flex" justify="center">
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography>Adet</Typography>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Tooltip title="Adet">
                        <InputNumber
                          size="small"
                          min={1}
                          max={1000}
                          value={furnitureUnits}
                          style={{ width: '90%' }}
                          onChange={val => {
                            setFurnitureUnits(val);
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Col>

                  {/* <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography>Genişlik (cm)</Typography>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Tooltip title="Genişlik">
                        <InputNumber
                          size="small"
                          min={1}
                          max={300}
                          value={width}
                          style={{ width: '90%' }}
                          onChange={val => {
                            val < 301
                              ? setWidth(val)
                              : message.warning('Genişlik 3 metreyi geçmemeli.');
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Col> */}

                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography>Derinlik (cm)</Typography>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Tooltip title="Derinlik">
                        <InputNumber
                          size="small"
                          min={1}
                          max={300}
                          value={depth}
                          style={{ width: '90%' }}
                          onChange={val => {
                            val < 301
                              ? setDepth(val)
                              : message.warning('Derinlik 3 metreyi geçmemeli.');
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography>Yükseklik (cm)</Typography>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Tooltip title="Yükseklik">
                        <InputNumber
                          size="small"
                          min={1}
                          max={300}
                          value={height}
                          style={{ width: '90%' }}
                          onChange={val => {
                            val < 301
                              ? setHeight(val)
                              : message.warning('Yükseklik 3 metreyi geçmemeli.');
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[48, 48]}>
              <Col>
                <Button
                  onClick={() =>
                    (width * height * depth) / 1000000 > 9
                      ? message.warning('Lütfen 9 metreküp üzerine çıkmayınız.')
                      : addToSelectedStuffs()
                  }
                  type="primary"
                  style={{ backgroundColor: '#f27a1a', color: '#fff', border: 'none' }}
                  icon="plus"
                >
                  Depoma Ekle
                </Button>
              </Col>
            </Row>
          </Card>
        )}
      </Row>
    </>
  );
};

export default FurnitureDetail;
