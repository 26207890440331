import React from 'react';
import { ExternalScripts, FloatButton, Footer, Header } from 'components';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const faq = () => {
  const questions = [
    /*0*/ 'DepoPro Nedir?',
    /*1*/ 'Eşyalarım güvende mi?',
    /*2*/ 'İhtiyacım olan depo sayısını nasıl belirleyebilirim?',
    /*3*/ 'Nakliye hizmeti nedir?',
    /*4*/ 'DepoPro dışında bir nakliye firması ile eşyalarımı depodan alabilir miyim?',
    /*5*/ 'Eşyalarımı paketlemek için malzeme talep edebilir miyim?',
    /*6*/ 'Eşyalarımı ne kadar süre depolayabilirim?',
    /*7*/ 'Eşyalarımı DepoPro’dan geri almak istediğimde ne yapmalıyım?',
    /*8*/ 'Eşyalarımı kendim gidip görmek veya depomdan bir şey almak istediğimde ne yapmalıyım?',
    /*9*/ 'Anahtarımı kaybedersem ne olur?',
    /*10*/ 'Depolanması yasak olan bir şey var mı?',
    /*11*/ 'DepoPro Mağaza nedir?',
    /*12*/ 'Sözleşme',
    /*13*/ 'Sözleşme’nin uzatılması.',
    /*14*/ 'Sözleşmemde yazan süreden daha önce çıkmak istediğimde sorun olur mu?',
    /*15*/ 'Kira ödemeleri nasıl yapılıyor?',
  ];
  const answers = [
    /*0*/ 'DepoPro, güvenli ve profesyonel bir depolama hizmetidir. ',
    /*0*/ 'İhtiyaç duyduğunuz tüm eşyaları DepoPro güvencesi ile depolayabilir veya e-ticaret için aradığınız hizmeti, depolama ve paketleme alanını DepoPro’da bulabilirsiniz.',
    /*1*/ 'DepoPro 24 saat güvenlik sağlanan bir AVM içinde bulunmaktadır. Buna ilave olarak hareket algılamalı alarm sistemleri ve kör noktası olmayan 24 saat kayıt yapan gece görüş özellikli kamera sistemleriyle donatılmıştır. Hava sirkülasyonu sağlayan RotateAir depo kapıları sayesinde eşyalarınız rutubet ve küften korunmuş olur. Düzenli olarak ilaçlanan depo alanında, haşerelere karşı en üst düzey önlemler alınmaktadır.',
    /*2*/ ' 1.Depolamak istediğiniz eşyalarınızı web sitemizin      <a href="/#calculate"> hesaplama modülünü </a> kullanarak kendiniz hesaplayabilir, hemen deponuzu online olarak kiralayabilirsiniz. ',
    /*2*/ '2.	Uzmanlarımız tarafından sunulacak ekspertiz hizmetinden faydalanmak için  <a href="/#contact ">buradan </a>iletişime geçebilirsiniz.',
    /*2*/ '3.	Depolanacak eşyalarınızın listesiyle birlikte çağrı merkezimize canlı bağlanarak gerekli depo sayısını belirleyebilir ve kiralamanızı canlı olarak yapabilirsiniz.',
    /*2*/ '4.	Depolanacak eşyalarınızın bir listesini e-mail adresimize göndererek, size gerekecek olan depo adedini öğrenebilirsiniz.',
    /*3-7*/ 'DepoPro tarafından sunulan sigortalı nakliye hizmetinden faydalanabileceğiniz gibi dilerseniz ürünlerinizi DepoPro’ya kendiniz de getirebilirsiniz. Depopro nakliye hizmeti kapsamında isterseniz demontaj ve paketleme hizmeti de alabilirsiniz.( Kıyafet, tabak, bardak, vb. küçük eşya paketleme hizmetimiz bulunmamaktadır) DepoPro nakliye hizmeti hakkında detaylı bilgi için 0850 450 0 999 numaralı telefondan DepoPro çağrı merkezimize ulaşabilir, 0537 475 97 33 numaralı Whatsapp iletişim hattından anlık bilgi alabilirsiniz.',
    /*4-8*/ 'Evet. Bize 1 hafta öncesinden haber vermek koşuluyla, gelecek firma için vekalet formu doldurmanız ve gün belirtmeniz gerekmektedir.',
    /*5-9*/ 'Depopro depolayacağınız eşyaların en sağlıklı şekilde depolanması amacıyla paketlenmemiş eşyaların depolanmasına izin vermemektedir. Nakliye hizmetinden faydalanmamanız durumunda eşyalarınızı paketlemek için kolilere, köpüklere ve yardımcı ambalaj malzemelerine ihtiyacınız olabilir. Bu ihtiyaçlarınızı 1 hafta öncesinden bildirmeniz durumunda satın alacağınız ambalaj malzemeleri tarafınıza kargo ile gönderilebilmektedir.',
    /*6-10*/ 'Eşyanızı istediğiniz süre boyunca depolayabilirsiniz. Ücretlendirme işlemi minimum 1 ay üzerinden yapılmaktadır.',
    /*7-11*/ 'Eşya depolama hizmetinizi sonlandırmak istediğinizde, çağrı merkezimize ulaşmanız ve randevu kaydı oluşturmanız gerekmektedir. Eşyalarınızı teslim almak için sözleşmenizle birlikte DepoPro’ya gelmeniz ya da yeniden nakliye talebinde bulunarak adreste hazır bulunmanız yeterlidir.',
    /*8-12*/ 'Eşya depolama süreci boyunca, eşyalarınızdan bazılarına ihtiyaç duymanız halinde depo alanımızı randevu alarak ziyaret edebilirsiniz. Bize 24 saat öncesinden bilgi vermek koşuluyla, Pazartesi-Cuma 09:00 -18:00 saatleri arasında DepoPro’nuzun bulunduğu ana depomuza gelebilir ve eşya deponuzu ziyaret edebilirsiniz.',
    /*9-13*/ 'Deponuzu kiraladığınızda depo anahtarınız size teslim edilir. Depo anahtarınızı kaybetmeniz halinde, yeni kilit bedeli cari hesabınıza işlenecektir.',
    /*9-14*/ 'Mesai saatleri dışında gelmeniz gerektiği durumda bize 24 saat, Pazar günü gelmeniz gerektiğinde ise 48 saat öncesinden haber vermeniz yeterlidir. Çalışma saati dışında yapılacak depo ziyaretleri ücrete tabidir.',
    /*10-15*/ 'Tehlikeli, parlayıcı, patlayıcı, zehirli, yanıcı, yakıcı, radyoaktif madde, can ve mal güvenliği bakımından tehlikeli olan veya olabilecek her nevi katı, sıvı ve gaz halinde olan madde, akacak, kokacak, bozulacak erzak ve bozulabilecek her türlü madde, sağlığa zararlı, koku yayan veya rutubet içeren nitelikte olan madde, depolanacak yeri ve diğer depoları ve saklanan eşyaları tehdit edebilecek her türlü maddenin depolanması yapılmamaktadır.',
    /*11-16*/ 'DepoPro’da depolarken aynı zamanda kazanabilirsiniz. Depolamanız gereken ancak satmak istediğiniz eşyalarınızı DepoPro internet sitesinde satışa çıkartabilir veya depo alanındaki DepoPro Mağaza vitrininde canlı sergileyebilirsiniz. DepoPro satışa sunduğunuz eşyalarınızı web sitesinde ve içerisinde bulunduğu AVM müşterisine, sizin belirlemiş olduğunuz fiyatlardan, sizin adınıza sunar. Alıcı ile görüşme konusunda kontrol tamamen sizdedir. DepoPro satmak istediğiniz eşya için size pazar sağlamakla beraber, eşyanızın başına gelmek zorunda kalmadan satış yapmanıza destek olur. ',
    /*11-17*/ 'DepoPro web sitesine koyduğunuz eşyalar için sizden ücret talep etmez. DepoPro Mağaza vitrininde canlı olarak sergilemek istediğiniz eşyalar için ürünün kapladığı alana göre hesaplanacak ücret cari hesabınıza yansıtılır.',
    /*12-18*/ 'Eşya depolama süresi fark etmeksizin her müşteri bir sözleşme imzalamak zorundadır. Sözleşmeniz eşyalarınızı depolamak istediğiniz süre kadar yapılır ve bu süre içerisinde geçerli olur.',
    /*13-19*/ 'Sözleşme süreniz sona erdiğinde, depo çıkış işlemi için bilgi verilmemesi durumunda  sözleşmeniz otomatik olarak yenilenir.',
    /*13-20*/ 'Sözleşmenizi yenilemek istemiyorsanız, sözleşme bitiş tarihinizden en az 1 hafta önce bizi aramanız ve deponuzdaki eşyaları teslim almak üzere randevu kaydı oluşturmanız gerekmektedir.',
    /*14-21*/ 'Hayır. Sözleşme sürenizden önce deponuzdaki eşyaları teslim almak istediğinizde bize bunu bildirmeniz yeterli olacaktır. Kullanmadığınız aylar için herhangi bir ücret talep edilmemektedir.',
    /*14-22*/ 'Eğer eşya depolamanızı, yıllık peşin ödeme yaparak gerçekleştirdiyseniz, DepoPro’da kaldığınız süre hesaplanıp, kullanmadığınız aylara ait bedel tarafınıza iade edilecektir. Tüm hesaplamalar aylık olarak yapılmaktadır. Gün bazında işlem yapılmamaktadır.',
    /*15-23*/ `İlk ay kira bedeli ve Depopro nakliye hizmeti alındıysa nakliye bedeli, kiralama başlangıcında tahsil edilmektedir. Sözleşme süresince devam eden ayların kira bedellerini Depopro’nun aşağıdaki banka hesabına havale/eft olarak veya <a href=/pay/> online ödeme </a> sayfamızdan kredi kartıyla ödeme seçeneğiyle ödenebilir. </br>
     Tamamı peşin ödeme yapılmayan tüm depolama sözleşmelerinde 3 aylık kira bedeli tutarında depozito alınmakta, kiralama süresi sonuna göre yapılacak cari mutabakat sonucunda iade edilmektedir. </br>
    </br> 
    DEPOPRO DEPOLAMA HİZM.TİC.A.Ş. </br>
KUVEYTTÜRK
İKİTELLİ </br>
TR07 0020 5000 0947 6342 8000 01`,
  ];

  return (
    <>
      <ExternalScripts />
      <div>
        <Header />
        <section
          className="cover-features cover imagebg space--lg parallax"
          data-overlay="8"
          style={{ backgroundImage: "url('img/promo-11.jpg')", backgroundSize: 'cover' }}
        >
          <div className="container">
            <div className="col-lg-12 text-center" style={{ marginBottom: -50 }}>
              <h1>Sık Sorulan Sorular</h1>
            </div>
            <div className="row">
              <Collapse
                bordered={false}
                ghost={true}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
                style={{
                  background: 'transparent',
                  width: '100%',
                  fontSize: '16px',
                  fontFamily: 'unset',
                  borderColor: 'black',
                }}
              >
                <Panel
                  header={<b>{questions[0]}</b>}
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[0] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[1] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[1]}</b>}
                  key="2"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[2] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[2]}</b>}
                  key="3"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[3] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[4] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[5] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[6] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[3]}</b>}
                  key="4"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[7] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[4]}</b>}
                  key="5"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[8] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[5]}</b>}
                  key="6"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[9] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[6]}</b>}
                  key="7"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[10] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[7]}</b>}
                  key="8"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[11] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[8]}</b>}
                  key="9"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[12] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[9]}</b>}
                  key="10"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[13] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[14] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[10]}</b>}
                  key="11"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[15] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[11]}</b>}
                  key="12"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[16] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[17] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[12]}</b>}
                  key="13"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[18] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[13]}</b>}
                  key="14"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[19] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[20] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[14]}</b>}
                  key="15"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[21] }}></p>
                  <p dangerouslySetInnerHTML={{ __html: answers[22] }}></p>
                </Panel>
                <Panel
                  header={<b>{questions[15]}</b>}
                  key="16"
                  className="site-collapse-custom-panel"
                >
                  <p dangerouslySetInnerHTML={{ __html: answers[23] }}></p>
                </Panel>
              </Collapse>
            </div>
          </div>
        </section>
        <Footer />
        <FloatButton />
      </div>
    </>
  );
};
export default faq;
