export const formUrlEncoded = data => {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
};

/* export const generateOfferCode = () => {
  const chars = 'ABCDEFGHIJKLMNOPQPRSTUVWXYZ';
  let numbers = '0123456789';

  let code_length = 6;
  let code = '';

  for (let i = 0; i < code_length; i++) {
    if (i % 2) {
      let rnum = Math.floor(Math.random() * chars.length);
      code += chars[rnum];
    } else {
      let rnum2 = Math.floor(Math.random() * numbers.length);
      code += numbers[rnum2];
    }
  }
  return code;
}; */

export const emailPattern = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$/;
export const emailPatternValidate = email => {
  if (email.match(emailPattern) != null) {
    return true;
  }
  return false;
};

export const calculateStuffInfo = selectedStuffs => {
  const perUnit = 9;
  const totalArea = selectedStuffs.reduce((acc, { width, height, depth, furnitureUnits }) => {
    const m3 = ((width * height * depth) / 1000000) * furnitureUnits * 1.1;
    return acc + m3;
  }, 0);

  const safeTotalArea = totalArea;
  const storeUnits = Math.ceil(safeTotalArea / perUnit);

  return {
    safeTotalArea,
    storeUnits,
  };
};

const searchString = (searchText, mainText, method, lang = 'tr') => {
  if (Array.isArray(searchText)) {
    return searchText[method](item => searchString(item, mainText, method));
  } else if (typeof searchText === 'string') {
    return mainText.toLocaleUpperCase(lang).includes(searchText.toLocaleUpperCase(lang));
  }
};

export const searchStringEvery = (searchText, mainText) =>
  searchString(searchText, mainText, 'every');

export const searchStringSome = (searchText, mainText) =>
  searchString(searchText, mainText, 'some');

export const searchByTextInArray = (array, searchText) => {
  return array.filter(item =>
    Object.keys(item).some(field =>
      item[field]
        .toString()
        .toLocaleLowerCase('TR')
        .includes(searchText.toString().toLocaleLowerCase('TR')),
    ),
  );
};

export const searchByTextInObject = (array, objectName, searchText) => {
  return array.filter(item =>
    Object.keys(item[objectName]).some(field =>
      item[objectName][field]
        .toString()
        .toLocaleLowerCase('TR')
        .includes(searchText.toString().toLocaleLowerCase('TR')),
    ),
  );
};
