import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authAction, portalAction, storeAction } from 'redux/modules';
import { Customer, Depot, Product, OrdersTable, Offer } from 'components';
import { Card, Drawer, Menu, Row, Tabs, Spin } from 'antd';

const { TabPane } = Tabs;

const Portal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const activeTabKey = useSelector(store => store.portal.activeTabKey);
  const isLoading = useSelector(
    store =>
      store.portal.isLoading ||
      store.order.isLoading ||
      store.stuff.isLoading ||
      store.customer.isLoading,
  );

  useEffect(() => {
    dispatch(storeAction.getAll());
  }, []);

  const showDrawer = () => {
    setVisible(prev => !prev);
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: 99,
          width: '100%',
          backgroundColor: '#f27a1a',
          marginTop: -20,
        }}
      >
        <div className="logo" />
        <Menu
          theme="light"
          mode="horizontal"
          /* defaultSelectedKeys={['2']} */ style={{
            float: 'right',
            backgroundColor: '#f27a1a',
            color: '#fff',
          }}
        >
          <Menu.Item key="1" onClick={() => showDrawer()}>
            Depolar
          </Menu.Item>
          {/* <Menu.Item key="2">Kredi Kartı ile Ödeme Al</Menu.Item> */}
          <Menu.Item
            key="2"
            onClick={() => {
              dispatch(authAction.logout());
            }}
          >
            Çıkış
          </Menu.Item>
        </Menu>
      </div>
      <div
        style={{
          background: '#fff',
          minHeight: '200px',
          maxWidth: '1248px',
          margin: '0px auto 0px auto',
        }}
      >
        <Drawer
          title="Depolar"
          width={1024}
          placement="right"
          closable={true}
          onClose={() => showDrawer()}
          visible={visible}
        >
          <Depot />
        </Drawer>

        <Spin spinning={isLoading} size="large">
          <Row
            style={{
              marginTop: 20,
              display: 'block',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <Card style={{ borderRadius: 10 }} style={{ marginTop: 50 }}>
              <Tabs
                activeKey={activeTabKey}
                onTabClick={key => {
                  dispatch(portalAction.setActiveTabKey(key));
                  dispatch(authAction.self());
                }}
              >
                <TabPane tab="Ürün" key="1">
                  <Product />
                </TabPane>
                <TabPane tab="Müşteri" key="2">
                  <Customer />
                </TabPane>
                <TabPane tab="Sipariş" key="3">
                  <OrdersTable />
                </TabPane>
                <TabPane tab="Teklif" key="4">
                  <Offer />
                </TabPane>
                <TabPane tab="Depo Kirala" key="5">
                  Depo Kiralama
                  {/* <Row>
                    <Col span={12} style={{ padding: 10 }}>
                      <LeasingForm />
                    </Col>
                    <Col span={12}>
                      <FurnitureDetail />
                    </Col>
                  </Row> */}
                </TabPane>
                <TabPane tab="Rapor" key="6">
                  Raporlar
                </TabPane>
              </Tabs>
            </Card>
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default Portal;
