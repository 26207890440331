export const LIST_SHIPPING_PRICE_MULTIPLIER_PENDING = 'LIST_SHIPPING_PRICE_MULTIPLIER_PENDING';
export const LIST_SHIPPING_PRICE_MULTIPLIER_FULFILLED = 'LIST_SHIPPING_PRICE_MULTIPLIER_FULFILLED';
export const LIST_SHIPPING_PRICE_MULTIPLIER_REJECTED = 'LIST_SHIPPING_PRICE_MULTIPLIER_REJECTED';

export const GET_SHIPPING_PRICE_MULTIPLIER_PENDING = 'GET_SHIPPING_PRICE_MULTIPLIER_PENDING';
export const GET_SHIPPING_PRICE_MULTIPLIER_FULFILLED = 'GET_SHIPPING_PRICE_MULTIPLIER_FULFILLED';
export const GET_SHIPPING_PRICE_MULTIPLIER_REJECTED = 'GET_SHIPPING_PRICE_MULTIPLIER_REJECTED';

export const CREATE_SHIPPING_PRICE_MULTIPLIER_PENDING = 'CREATE_SHIPPING_PRICE_MULTIPLIER_PENDING';
export const CREATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED =
  'CREATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED';
export const CREATE_SHIPPING_PRICE_MULTIPLIER_REJECTED =
  'CREATE_SHIPPING_PRICE_MULTIPLIER_REJECTED';

export const UPDATE_SHIPPING_PRICE_MULTIPLIER_PENDING = 'UPDATE_SHIPPING_PRICE_MULTIPLIER_PENDING';
export const UPDATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED =
  'UPDATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED';
export const UPDATE_SHIPPING_PRICE_MULTIPLIER_REJECTED =
  'UPDATE_SHIPPING_PRICE_MULTIPLIER_REJECTED';

export const DELETE_SHIPPING_PRICE_MULTIPLIER_PENDING = 'DELETE_SHIPPING_PRICE_MULTIPLIER_PENDING';
export const DELETE_SHIPPING_PRICE_MULTIPLIER_FULFILLED =
  'DELETE_SHIPPING_PRICE_MULTIPLIER_FULFILLED';
export const DELETE_SHIPPING_PRICE_MULTIPLIER_REJECTED =
  'DELETE_SHIPPING_PRICE_MULTIPLIER_REJECTED';
