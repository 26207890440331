import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Icon, Row, Statistic, Table } from 'antd';
import { stuffAction } from 'redux/modules';

export const EstimatedInfo = ({ totalArea, storeUnits, remainingArea }) => {
  return (
    <Row style={{ marginTop: 30, textAlign: 'center' }}>
      <Col sm={24} md={12} lg={8}>
        <Statistic
          title={<b>Toplam Hacim</b>}
          precision={2}
          value={totalArea.toFixed(2)}
          valueStyle={{ color: '#000' }}
          prefix={<Icon type="calculator" />}
          suffix={
            <p>
              m<sup>3</sup>
            </p>
          }
        />
      </Col>
      <Col sm={24} md={12} lg={8}>
        <Statistic
          title={<b>Tahmini Depo Sayısı</b>}
          precision={0}
          value={storeUnits}
          valueStyle={{ color: '#000' }}
          prefix={<Icon type="like" />}
          suffix={<p>Adet</p>}
        />
      </Col>
      <Col sm={24} md={12} lg={8}>
        <Statistic
          title={<b>Deponuzda Kalan Hacim</b>}
          precision={2}
          value={remainingArea}
          valueStyle={{ color: '#000' }}
          prefix={<Icon type="calculator" />}
          suffix={
            <p>
              m<sup>3</sup>
            </p>
          }
        />
      </Col>
    </Row>
  );
};

const SelectedListNew = () => {
  const dispatch = useDispatch();
  const selectedStuffs = useSelector(store => store.stuff.selectedStuffs);

  const [totalArea, setTotalArea] = useState(0);
  const [storeUnits, setStoreUnits] = useState(0);
  const [remainingArea, setRemainingArea] = useState(0);

  const perUnit = 9;

  const calculate = useCallback(() => {
    const totalArea = selectedStuffs.reduce((acc, { width, height, depth, furnitureUnits }) => {
      const m3 = ((width * height * depth) / 1000000) * furnitureUnits * 1.1;
      return acc + m3;
    }, 0);

    setTotalArea(totalArea);
    setStoreUnits(Math.ceil(totalArea / perUnit));
    setRemainingArea(Math.ceil(totalArea / perUnit) * perUnit - totalArea);
  }, [selectedStuffs, storeUnits]);

  useEffect(() => {
    calculate();
  }, [selectedStuffs, calculate]);

  const columns = [
    {
      title: <>Ürün</>,
      dataIndex: 'name',
      key: 'name',
      render: text => <b>{text}</b>,
    },
    {
      title: 'Adet',
      dataIndex: 'furnitureUnits',
      key: 'furnitureUnits',
    },
    {
      title: 'Ölçü (cm)',
      render: record => record.width + 'x' + record.height + 'x' + record.depth,
    },
    {
      title: (
        <>
          Hacim (m<sup>3</sup>)
        </>
      ),
      render: record => (
        <>
          {(
            ((record.width * record.height * record.depth) / 1000000) *
            record.furnitureUnits *
            1.1
          ).toFixed(2)}
          m<sup>3</sup>
        </>
      ),
    },
    {
      title: 'Kaldır',
      render: record => (
        <Icon
          onClick={() => {
            const filteredSelectedStuffs = selectedStuffs.filter(
              selected => selected.uuid !== record.uuid,
            );
            dispatch(stuffAction.listSelectedStuffs(filteredSelectedStuffs));
          }}
          type="delete"
        />
      ),
    },
  ];

  return (
    <>
      <Row
        justify="center"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ color: '#f27a1a' }}>Sipariş Özeti</h2>
        </div>
      </Row>
      <Card
        style={{ border: 'none', borderRadius: 20, height: '100%', width: '80%', margin: 'auto' }}
      >
        <Table
          bordered
          size={'small'}
          dataSource={selectedStuffs}
          columns={columns}
          rowKey={(item, index) => index}
          pagination={false}
          style={{ minHeight: '55vh', maxHeight: '55vh', overflow: 'scroll' }}
        />
        <EstimatedInfo
          totalArea={totalArea}
          storeUnits={storeUnits}
          remainingArea={remainingArea}
        />
      </Card>
    </>
  );
};

export default SelectedListNew;
