import React, { useState } from 'react';
import { Typography } from 'antd';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const imageId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const { Text } = Typography;

const Gallery = () => {
  const [value, setValue] = useState(0);

  return (
    <>
      <div>
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <h2 style={{ color: '#f27a1a' }}>Galeri</h2>
          <div className="col-sm-12 col-md-10 offset-md-1">
            <div>
              <Carousel value={value} onChange={val => setValue(val)} arrows>
                {imageId.map((id, index) => (
                  <img
                    key={index}
                    className="img-example"
                    alt="background"
                    src={`img/gallery-images/store${id}.jpg`}
                  />
                ))}
              </Carousel>
              <Dots
                value={value}
                onChange={val => setValue(val)}
                thumbnails={imageId.map((id, index) => (
                  <img
                    className="img-example-small"
                    alt="background"
                    src={`img/gallery-images/store${id}.jpg`}
                  />
                ))}
              />
              <h4 style={{ color: '#f27a1a', fontStyle: 'italic', marginTop: 25 }}>
                <Text
                  style={{
                    fontSize: 15,
                  }}
                >
                  * Galeride yer alan fotoğraflar DepoPro'da çekilmiş gerçek fotoğraflardır.
                </Text>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
