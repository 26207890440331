import React from 'react';

const servicesData = [
  {
    icon: 'fas fa-warehouse',
    title: 'Kişisel Depolama',
    description:
      'Daha küçük bir eve mi taşındınız ? E-ticaret yapıyorsunuz ancak deponuz ve  paketleme alanınız mı yok? DepoPro tarafından güvenliği sağlanmış depolarınız kullanımınıza açıktır. ',
  },
  {
    icon: 'fas fa-boxes',
    title: 'Arşiv Depolama ',
    description:
      'Özellikle m<sup>2 </sup> başına kira ücreti yüksek olan her işletmede arşiv için ayrılmış bir alan ya da oda vardır. Arşivinizi DepoPro’ya taşımak, ofislerinizde size ekstra alan açıp, daha efektif çalışmanızı sağlayacaktır.',
  },
  {
    icon: 'fas fa-shipping-fast',
    title: 'Nakliye',
    description:
      'Depolamak istediğiniz eşyanız ya da arşiviniz için nakliye hizmetimizden faydalanabilirsiniz.',
  },
  {
    icon: 'fas fa-store',
    title: 'e-Ticaret Ofis Kiralama',
    description:
      'E-ticaret yapan, home office çalışıp toplantı odasına ihtiyacı olan her tüzel ve gerçek kişi için bölmeli ofisler ve toplantı odası kullanımınıza açıktır.',
  },
  {
    icon: 'fas fa-cart-arrow-down',
    title: 'DepoPro Mağaza',
    description:
      'DepoPro satmak istediğiniz eşyalarınız için size pazar sağlamakla beraber, zahmete girmeden satış yapmanıza destek olur.',
  },

  {
    icon: 'fas fa-clipboard-list',
    title: 'Expertiz',
    description:
      'Eşyanızın depolanması için gereken depo miktarını, expertiz ekibimize hesaplatabilir, ya da online olarak kendiniz de ücretsiz hesaplayabilirsiniz.',
  },
];

const ServicesSection = () => {
  return (
    <section className="text-center " id="services">
      <div className="container">
        <div className="row text-block">
          <div className="col-md-12">
            <h2 className="primaryColor">
              Hizmetlerimiz
              <br />
              <br />
            </h2>
          </div>
        </div>
        <div className="row">
          {servicesData.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-6">
              <div className="text-block">
                <i className={service.icon} style={{ fontSize: 60, color: 'gray', margin: 5 }} />
                <h4 className="primaryColor">{service.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
