import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeAction, shippingAction } from 'redux/modules';
import { Card, Col, Checkbox, DatePicker, Row, Select, Statistic, Typography } from 'antd';
import { calculateStuffInfo } from 'utils/utils';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

const Box = ({ depot, depotPrice, ...otherProps }) => {
  const dispatch = useDispatch();
  const { selectedDepotList, shared, contractDates } = useSelector(store => store.store);

  //available: false     notAvailable: true
  const depotAvailableShared =
    depot.adminDisabled ||
    (!!depot.customer && !!depot.customer2) ||
    (!depot.shared && (!!depot.customer || !!depot.customer2));

  const depotAvailableNonShared =
    depot.adminDisabled ||
    (!!depot.customer && !!depot.customer2) ||
    (!depot.shared && (!!depot.customer || !!depot.customer2)) ||
    (depot.shared && (!!depot.customer || !!depot.customer2));

  const depotAvailable = shared ? depotAvailableShared : depotAvailableNonShared;

  const startDate = contractDates?.contractStartDate;
  const endDate = contractDates?.contractEndDate;
  const date = Math.round(moment(endDate ? endDate : 0).diff(endDate ? startDate : 0, 'days') / 30);
  const monthlyDepotPrice = depotPrice * date;
  // const latestPrice = selectedDepotList?.length * monthlyDepotPrice;

  return (
    <Card.Grid
      style={{
        width: '5.5%',
        height: '125px',
        textAlign: 'center',
        padding: '15px 1px 1px 1px',
        margin: 3,
        borderRadius: '10px',
        minWidth: '50px',
      }}
      {...otherProps}
    >
      {!depotAvailable ? (
        <>
          <div>
            <b>
              {depot.blockName}-{depot.storeNo}
            </b>
          </div>
          <div style={{ margin: '10px 0px 10px 0px' }}>
            <Checkbox
              defaultChecked={selectedDepotList.filter(item => item.id == depot.id).length != 0}
              onChange={e => {
                e.target.checked &&
                  dispatch(storeAction.setSelectedDepots([...selectedDepotList, depot]));
                !e.target.checked &&
                  dispatch(
                    storeAction.setSelectedDepots(
                      selectedDepotList.filter(item => item.id != depot.id),
                    ),
                  );
              }}
            />
          </div>
          <span style={{ fontSize: '14px', color: '#f27a1a' }}>
            {depotPrice ? depotPrice : 0}
            TL
          </span>
        </>
      ) : (
        <div style={{ padding: '35px 0px', color: '#ccc' }}>DOLU</div>
      )}
    </Card.Grid>
  );
};

const generateBlockList = (blockKey, storeList) => {
  return storeList.reduce((acc, item) => {
    const isSameBlock = blockKey === item.blockName + ' Blok';
    if (isSameBlock) return [...acc, item];
    return acc;
  }, []);
};

/* const calculateTotalPrice = stuffList => {
  return stuffList.reduce((acc, item) => {
    return item.basePrice * item.priceMultiplier + Number(acc);
  }, []);
}; */

const DepotUser = ({ style }) => {
  const dispatch = useDispatch();
  const { storeList, selectedDepotList, shared, contractDates } = useSelector(store => store.store);
  const activeTab = useSelector(store => store.portal.activeTabKey);
  const selectedStuffs = useSelector(store => store.stuff.selectedStuffs);

  const [selectedTab, setSelectedTab] = useState(null);
  const [tabList, setTabList] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [stuffInfo, setStuffInfo] = useState({});
  const hasData = useMemo(() => tabList && contentList, [tabList, contentList]);
  const config = useSelector(state => state.config);
  const [totalArea, setTotalArea] = useState(0);
  const [storeUnits, setStoreUnits] = useState(0);
  const [remainingArea, setRemainingArea] = useState(0);

  const generateContentList = useCallback((uniqueDepotKeys, blockList, depotPrice) => {
    return uniqueDepotKeys.reduce((acc, item, index) => {
      const blockDepotsData = blockList[index];

      // Bloğa ait depoları oluşturur
      const mappedBlockDepots = blockDepotsData.map(depotData => (
        <Box key={depotData.id} depot={depotData} depotPrice={depotPrice} />
      ));
      return { ...acc, [item]: mappedBlockDepots };
    }, {});
  }, []);

  useEffect(() => {
    dispatch(storeAction.getAll());
    dispatch(shippingAction.getCurrentDepotPrice());
    setStuffInfo(calculateStuffInfo(selectedStuffs));
    return () => {};
  }, []);
  useEffect(() => {
    if (!storeList[0]) return;
    const depotKeys = storeList.map(item => item.blockName + ' Blok');
    const uniqueDepotKeys = Array.from(new Set(depotKeys)).sort();
    const tabList = uniqueDepotKeys.map(item => ({ key: item, tab: item }));
    // 'G', 'H' gibi bloklara göre depoları parçalar
    const blockList = uniqueDepotKeys.map(blockKey => generateBlockList(blockKey, storeList));
    const contentList = generateContentList(uniqueDepotKeys, blockList, config?.depotPrice);

    setSelectedTab(tabList[0].key);
    setTabList(tabList);
    setContentList(contentList);
    return () => {};
  }, [storeList, config]);

  const perUnit = 9;

  const calculate = useCallback(() => {
    const totalArea = selectedStuffs.reduce((acc, { width, height, depth, furnitureUnits }) => {
      const m3 = ((width * height * depth) / 1000000) * furnitureUnits * 1.1;
      return acc + m3;
    }, 0);

    setTotalArea(totalArea);
    setStoreUnits(Math.ceil(totalArea / perUnit));
    setRemainingArea(Math.ceil(totalArea / perUnit) * perUnit - totalArea);
    let monthlyPrice;
    switch (Math.ceil(totalArea / perUnit)) {
      case 0:
        monthlyPrice = 0;
        break;
      case 1:
        monthlyPrice = 250;
        break;
      case 2:
        monthlyPrice = 250;
        break;
      case 3:
        monthlyPrice = 241.6;
        break;
      default:
        monthlyPrice = 225;
        break;
    }
    /* setTotalPrice(Math.ceil(storeUnits * monthlyPrice)); */
  }, [selectedStuffs, storeUnits]);

  useEffect(() => {
    calculate();
  }, [selectedStuffs, calculate]);

  return (
    <Row justify="center" gutter={[16, 8]}>
      <Col span={24} style={{ textAlign: 'center', marginTop: '-30px' }}>
        <Text type="warning">Kiralamalar minimum 1 ay üzerinden ücretlendirilmektedir.</Text>
      </Col>
      <Col span={12} style={{ textAlign: 'center', marginTop: '-40px' }}>
        <Statistic
          title={
            <b>
              İhtiyacınız Olan <br /> Depo Sayısı
            </b>
          }
          precision={0}
          //value={storeUnits}
          value={(stuffInfo?.storeUnits ? stuffInfo.storeUnits : 0).toFixed(2)}
          suffix={<p>Adet</p>}
        />
      </Col>
      <Col span={12} style={{ textAlign: 'center', marginTop: '-40px' }}>
        <Statistic
          title={
            <b>
              Seçtiğiniz <br />
              Depo Sayısı
            </b>
          }
          precision={0}
          value={selectedDepotList?.length}
          suffix={<p>Adet</p>}
        />
      </Col>

      {/* <Col span={10}>
        <Tooltip
          title="Deponuzu paylaşmak isteyip istemediğinizi buradan belirtebilirsiniz."
          placement="top"
        >
          Paylaşımlı Depo:{' '}
          <Switch defaultChecked onChange={() => dispatch(storeAction.setSharedDepots(!shared))} />
        </Tooltip>
      </Col> */}
      <Col span={24} style={style}>
        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <div style={{ width: 850, margin: 'auto' }}>
            <Row justify="center" style={{ width: '100%', marginBottom: 10 }}>
              <Col span={8}>
                <DatePicker
                  placeholder="Kira Başlangıç"
                  format={'L'}
                  defaultValue={contractDates?.contractStartDate}
                  style={{ width: '96%' }}
                  disabledDate={current => {
                    return !(current && current > moment());
                  }}
                  onChange={val => {
                    dispatch(
                      storeAction.setContractDates({
                        ...contractDates,
                        contractStartDate: val,
                      }),
                    );
                  }}
                />
              </Col>
              <Col span={8} style={{ padding: '0 0 0 5px' }}>
                <DatePicker
                  placeholder="Kira Bitiş"
                  format={'L'}
                  defaultValue={contractDates?.contractEndDate}
                  style={{ width: '96%' }}
                  disabledDate={current => {
                    return !(
                      current &&
                      (contractDates && contractDates.contractStartDate
                        ? moment(contractDates.contractStartDate).add(30, 'days') < current
                        : false)
                    );
                  }}
                  onChange={val =>
                    dispatch(
                      storeAction.setContractDates({
                        ...contractDates,
                        contractEndDate: val,
                      }),
                    )
                  }
                />
              </Col>
              <Col span={8} style={{ justifySelf: 'flex-end', padding: '0 0 0 5px' }}>
                <Select
                  showSearch
                  style={{ width: '96%' }}
                  placeholder="Depo Alanı Seçin"
                  value={selectedTab}
                  onSelect={val => {
                    setSelectedTab(val);
                  }}
                >
                  {tabList?.length > 0 &&
                    tabList.map(tab => (
                      <Option key={(item, index) => index} value={tab.key}>
                        {tab.key}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
            <h4>Üst Kat</h4>

            {hasData && contentList[selectedTab].map(item => item.props.depot.floor && item)}
            <br />
            <h4>Alt Kat</h4>
            {hasData && contentList[selectedTab].map(item => !item.props.depot.floor && item)}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DepotUser;
