import React from 'react';

const FloatButton = () => {
  return (
    <>
      {/* <a
        className="back-to-top inner-link hidden-sm hidden-xs"
        href="#start"
        data-scroll-class="100vh:active"
      >
        <i className="stack-interface stack-up-open-big"></i>
      </a> */}

      <a href="tel:08504500999" className="float">
        <i className="icon icon--sm icon-Old-Telephone my-float"></i>
      </a>
      <div className="label-container">
        <div className="label-text">Hemen Aramak İçin Tıklayın.</div>
      </div>

      <a href="https://wa.me/905374759733" target="_blank" className="float whatsapp">
        <i className="socicon socicon-whatsapp icon icon--sm"></i>
      </a>
      <div className="label-container whatsapp">
        <div className="label-text">Mesaj Atmak İçin Tıklayın.</div>
      </div>
    </>
  );
};

export default FloatButton;
