import * as offerTypes from './offer.type';

const initialState = {
  error: null,
  isLoading: false,
  visible: false,
  offers: [],
  record: {},
  offerFurnitures: [],
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case `${offerTypes.LIST_OFFER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${offerTypes.LIST_OFFER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        offers: action.payload,
      };
    case `${offerTypes.LIST_OFFER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        offers: null,
      };

    case `${offerTypes.CREATE_OFFER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${offerTypes.CREATE_OFFER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentCustomer: action.payload,
        offerRegister: action.payload,
      };
    case `${offerTypes.CREATE_OFFER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        offers: null,
      };
    case `${offerTypes.UPDATE_OFFER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${offerTypes.UPDATE_OFFER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${offerTypes.UPDATE_OFFER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        offers: null,
      };
    case `${offerTypes.DELETE_OFFER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${offerTypes.DELETE_OFFER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${offerTypes.DELETE_OFFER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${offerTypes.HANDLE_OFFER_ADD_MODAL_VISIBLE}`:
      return {
        ...state,
        visible: !state.visible,
        record: action.payload,
      };

    default:
      return state;
  }
};

export default offerReducer;
