import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Steps } from 'antd';

const { Step } = Steps;

const ShopHeader = ({ title, currentStep }) => {
  return (
    <>
      <Row justify="center" align="middle" style={{ margin: '5px 5px 0px 5px' }}>
        <Col span={8} style={{ textAlign: 'left' }}>
          <Link to="/">
            <img
              className="logo"
              alt="logo"
              src="img/depopro_logo.png"
              style={{ maxWidth: '6em' }}
            />
          </Link>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <h2>{title && title}</h2>
        </Col>
        <Col span={8} style={{ textAlign: 'right', marginTop: 75 }}>
          {/* <h2 style={{ color: '#000 !important' }}>
            {stuffListInfo().safeTotalArea.toFixed(2)} m<sup>3</sup>
          </h2> */}
        </Col>
      </Row>

      <Steps
        className="site-navigation-steps"
        style={{ overflow: 'hidden' }}
        current={currentStep ? currentStep - 1 : 0}
      >
        <Step title="Sipariş Oluştur" />
        <Step title="İncele - Kirala" />
        <Step title="Fatura Bilgileri" />
      </Steps>
    </>
  );
};

export default ShopHeader;
