import React, { useEffect } from 'react';
import { paymentAction } from 'redux/modules';
import { useDispatch } from 'react-redux';
import { Result, Button } from 'antd';

const Success = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentAction.clearLocalStorage());
  }, []);

  return (
    <>
      <section style={{ width: '100%', height: '100%', display: 'table', textAlign: 'center' }}>
        <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
          <Result
            status="success"
            icon={<img src={'img/Success.svg'} />}
            title={
              <div style={{ color: '#508B16', fontSize: 26, fontWeight: 'bold' }}>
                Siparişiniz Bize Ulaşmıştır
              </div>
            }
            subTitle={
              <div>
                DepoPro'yu Tercih Ettiğiniz İçin Teşekkür Ederiz
                <br /> En Kısa Sürede Sizlerle İletişime Geçeceğiz
              </div>
            }
            extra={
              <Button
                style={{ backgroundColor: '#f27a1a', color: '#fff', border: 'none' }}
                href={'http://depopro.com.tr'}
                type="primary"
              >
                Ana Sayfa
              </Button>
            }
          />
        </div>
      </section>
    </>
  );
};

export default Success;
