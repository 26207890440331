import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderAction, shippingAction } from 'redux/modules';
import { Col, DatePicker, Form, message, Modal, Row, Select, Statistic } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

const ShippingModal = ({ addProductToBasket, closeModal, product, visible }) => {
  const dispatch = useDispatch();
  const [district, setDistrict] = useState(null);
  const [shippingDate, setShippingDate] = useState(moment().add(4, 'days'));
  const { shippingPriceList } = useSelector(state => state.shipping);

  useEffect(() => {
    dispatch(shippingAction.list());
  }, []);

  const ModalOkTextWithIcon = () => {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>
          <ShoppingCartOutlined /> Sepete Ekle
        </span>
      </>
    );
  };

  function disabledDate(current) {
    console.log(current.format('dddd'));
    return (
      (current &&
        current <
          moment()
            .endOf('day')
            .add(3, 'days')) ||
      current.format('dddd') === 'Pazar'
    );
  }

  return (
    <Modal
      title="Nakliye Hizmeti"
      centered
      visible={visible}
      okText={<ModalOkTextWithIcon />}
      onOk={() => {
        if (district) {
          dispatch(orderAction.setDistrict(district.district));
          dispatch(orderAction.setShippingDate(shippingDate));
          addProductToBasket({ ...product, price: district.districtPrice }, 0);
          closeModal();
          setDistrict(null);
        } else {
          message.error('Lütfen Verilerinizi Kontrol Edin.');
        }
      }}
      okButtonProps={{ style: { backgroundColor: '#f27a1a' } }}
      onCancel={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose={true}
    >
      <Form layout="horizontal">
        <Row justify="center">
          <Col offset={6} span={12}>
            <Form.Item>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="İlçe Seçiniz"
                onChange={value => setDistrict(shippingPriceList[value])}
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase('tr')
                    .indexOf(input.toLocaleLowerCase('tr')) >= 0
                }
                getPopupContainer={trigger => trigger.parentElement}
              >
                {shippingPriceList.map((item, index) => (
                  <Option key={index}>{item.district}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={6} span={12}>
            <Form.Item>
              <DatePicker
                disabledDate={disabledDate}
                defaultValue={moment().add(4, 'days')}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                onChange={value => setShippingDate(value)}
                getCalendarContainer={trigger => trigger.parentElement}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Form.Item>
              <Statistic
                title={<b>Tutar</b>}
                precision={2}
                value={((district ? district.districtPrice : 0) + 0).toFixed(2)}
                suffix={<p>TL</p>}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ShippingModal;
