import React, { useEffect, useState } from 'react';
import { contractBody } from 'constants/contract';
import { useDispatch, useSelector } from 'react-redux';
import { orderAction } from 'redux/modules';
import { Button, Checkbox, Col, message, Modal, Row } from 'antd';

const ShopFooter = ({ backTo, forward, step }) => {
  const dispatch = useDispatch();
  const { selectedStuffs } = useSelector(store => store.stuff);
  const { basketItems } = useSelector(store => store.product);
  const { currentCustomer } = useSelector(store => store.customer);
  const { district, shippingDate } = useSelector(store => store.order);

  const [contractModalVisible, setContractModalVisible] = useState(false);
  const [contractCheck, setContractCheck] = useState(false);

  const [orderRequest, setOrderRequest] = useState({
    productList: basketItems,
    customer: currentCustomer,
    district,
    shippingDate,
  });

  useEffect(() => {
    setOrderRequest({
      productList: basketItems,
      customer: currentCustomer,
      district,
      shippingDate,
    });
  }, [basketItems, currentCustomer, district, shippingDate]);

  return (
    <Row
      display="flex"
      justify="center"
      gutter={[32, 32]}
      style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 1,
        paddingBottom: 45,
        paddingLeft: 30,
      }}
    >
      <Modal
        title="Sipariş Ön Bilgilendirme Koşulları"
        centered
        visible={contractModalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setContractModalVisible(prev => !prev)}
        cancelButtonProps={{ style: { display: 'none' } }}
        destroyOnClose={true}
        width={window?.screen?.width < 1200 ? '75%' : '40%'}
      >
        <div style={{ padding: '25px' }} dangerouslySetInnerHTML={{ __html: contractBody }}></div>
      </Modal>

      {step === 3 && (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Checkbox onChange={() => setContractCheck(prev => !prev)}>
            <span
              style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
              onClick={() => setContractModalVisible(prev => !prev)}
            >
              Sipariş Ön bilgilendirme Koşullarını
            </span>{' '}
            okudum, onaylıyorum.
          </Checkbox>
        </Col>
      )}
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button
          disabled={step === -1 ? true : false}
          size="large"
          style={{
            borderRadius: 20,
            width: 150,
            color: '#f27a1a',
          }}
          onClick={backTo}
        >
          <span>Geri</span>
        </Button>
      </Col>
      <Col span={12} style={{ textAlign: 'left' }}>
        <Button
          size="large"
          disabled={
            step === 0 ? false : step === 1 ? false : step === 2 ? false : step === 3 ? false : true
          }
          type="primary"
          style={{
            borderRadius: 20,
            width: 150,
            backgroundColor: '#f27a1a',
            color: '#fff',
          }}
          onClick={() => {
            switch (step) {
              case -1:
                selectedStuffs?.length > 0
                  ? forward()
                  : message.warning('Lütfen Depolamak İstediğiniz Eşyaları Seçin.');
                break;
              case 0:
                forward();
                break;
              case 1:
                basketItems?.length > 0 ? forward() : message.warning('Lütfen Sepete Ürün Ekleyin');
                break;
              case 2:
                window.customerRegisterFormRef.current.props.onSubmit(null, forward);
                break;
              case 3:
                contractCheck
                  ? dispatch(orderAction.create(orderRequest))
                  : message.warning('Lütfen Ön Bilgilendirme koşullarını kabul edin.');
                break;

              default:
                break;
            }
          }}
        >
          {step === 3 ? <span>Sipariş Oluştur</span> : <span>İleri</span>}
        </Button>
      </Col>
    </Row>
  );
};

export default ShopFooter;
