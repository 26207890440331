import React from 'react';
import { Button } from 'antd';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelExport = props => {
  return (
    <>
      {props?.dataSource?.length > 0 && (
        <ExcelFile
          filename={`${props.name}Listesi`}
          element={
            <Button
              type="primary"
              icon="file-excel"
              style={{ backgroundColor: '#f27a1a', color: '#fff', width: '100%' }}
            >
              Excel
            </Button>
          }
        >
          <ExcelSheet
            data={props.dataSource}
            name={`${props.name}Listesi`}
            children={props.columns
              .map(
                column =>
                  column?.exportToExcel && <ExcelColumn label={column.title} value={column.key} />,
              )
              .filter(excelColumnValid => excelColumnValid)}
          />
        </ExcelFile>
      )}
    </>
  );
};

export default ExcelExport;
