import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from 'redux/modules';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const AddProductModal = props => {
  const dispatch = useDispatch();

  const { visible, record, image, loading } = useSelector(({ product }) => ({
    visible: product.productModalVisible,
    record: product.record,
    image: product.image,
    loading: product.isLoading,
  }));

  const [productType, setProductType] = useState(null);
  const {
    form: { validateFields, getFieldDecorator, resetFields },
  } = props;

  const handleVisible = () => {
    dispatch(productAction.handleVisibleProductModal());
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Yalnızca JPG/PNG dosyası yükleyebilirsiniz!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Resim 2 MB'tan küçük olmalıdır!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      destroyOnClose={true}
      title="DepoPro"
      visible={visible}
      onOk={handleVisible}
      onCancel={handleVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      centered={true}
      width={window?.screen?.width < 1024 ? '75%' : '30%'}
    >
      <Form
        onSubmit={event => {
          event.preventDefault();
          validateFields((err, values) => {
            if (!err) {
              values = {
                ...values,
                id: record?.id || null,
                districtPrice: productType !== 'SHIPPING' ? values.price : null,
                imageUrl: image,
              };
              record
                ? dispatch(productAction.update(values))
                : dispatch(productAction.create(values));
              setProductType(null);
              resetFields();
              handleVisible();
            } else {
              message.error('Lütfen Verilerinizi Kontrol Edin.');
            }
          });
        }}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('name', {
                initialValue: record?.name || '',
                rules: [{ required: true, message: 'Lütfen Ürün Adını Giriniz !' }],
              })(<Input placeholder="Ürün Adı" tooltiptitle="Ürün Adı" />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: record?.description || '',
                rules: [{ required: true, message: 'Lütfen Açıklama Giriniz !' }],
              })(<TextArea rows={2} placeholder="Açıklama" tooltiptitle="Ürün Açıklaması" />)}
            </Form.Item>
          </Col>
          <Col xs={record?.productType === 'SHIPPING' || productType === 'SHIPPING' ? 24 : 12}>
            <Form.Item>
              {getFieldDecorator('productType', {
                initialValue: record?.productType,
                rules: [{ required: true, message: 'Lütfen Ürün Tipini Seçiniz!' }],
              })(
                <Select placeholder="Ürün Tipi" onChange={value => setProductType(value)}>
                  <Option value="PRODUCT">Malzeme</Option>
                  <Option value="STORE">Depo</Option>
                  <Option value="SHIPPING">Hizmet</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            {record?.productType !== 'SHIPPING' && productType !== 'SHIPPING' && (
              <Form.Item>
                {getFieldDecorator('price', {
                  initialValue: record?.price || '',
                  rules: [{ required: true, message: 'Lütfen Fiyat Bilgisi Giriniz !' }],
                })(
                  <InputNumber
                    placeholder="Fiyat Bilgisi"
                    tooltiptitle="Fiyat Bilgisi"
                    min={1}
                    style={{ width: '100%' }}
                  />,
                )}
              </Form.Item>
            )}
          </Col>
          {(record?.productType === 'STORE' || productType === 'STORE') && (
            <>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('width', {
                    initialValue: record?.width,
                    rules: [{ required: true, message: 'Lütfen Depo Genişliğini Giriniz!' }],
                  })(
                    <InputNumber
                      placeholder="Genişlik"
                      tooltiptitle="Genişlik"
                      min={1}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('height', {
                    initialValue: record?.height,
                    rules: [{ required: true, message: 'Lütfen Depo Yüksekliğini Giriniz!' }],
                  })(
                    <InputNumber
                      placeholder="Yükseklik"
                      tooltiptitle="Yükseklik"
                      min={1}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('depth', {
                    initialValue: record?.depth,
                    rules: [{ required: true, message: 'Lütfen Depo Derinliğini Giriniz!' }],
                  })(
                    <InputNumber
                      placeholder="Derinlik"
                      tooltiptitle="Derinlik"
                      min={1}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('imageUrl', {
                initialValue: record?.imageUrl || '',
                rules: [{ required: true, message: 'Lütfen Resim Yükleyiniz!' }],
              })(
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  fileList={[image]}
                  beforeUpload={beforeUpload}
                  action={file => dispatch(productAction.imageUpload(file))}
                >
                  {image || record?.imageUrl ? (
                    <img src={image || record?.imageUrl} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>,
              )}
            </Form.Item>
          </Col>
          <Col offset={5} span={14}>
            <Form.Item>
              <Row>
                <Button
                  icon="edit"
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: '#f27a1a',
                    color: '#fff',
                    border: 'none',
                  }}
                >
                  {record ? 'Güncelle' : 'Kaydet'}
                </Button>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'offer' })(AddProductModal);
