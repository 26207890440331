import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerAction } from '../../redux/modules';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import InputMask from 'react-input-mask';
import { emailPatternValidate } from 'utils/utils';

const { TextArea } = Input;

const AddCustomerModal = props => {
  const dispatch = useDispatch();

  const { visible, record } = useSelector(({ customer }) => ({
    visible: customer.visible,
    record: customer.record,
  }));

  const {
    form: { validateFields, getFieldDecorator },
  } = props;

  const handleVisible = () => {
    dispatch(customerAction.handleVisible());
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Müşteri Ekle"
      visible={visible}
      onOk={handleVisible}
      onCancel={handleVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      centered={true}
      width={window?.screen?.width < 1024 ? '75%' : '30%'}
    >
      <Form
        onSubmit={event => {
          event.preventDefault();
          validateFields((err, values) => {
            if (!err) {
              values = {
                ...values,
                id: record?.id || null,
              };
              record
                ? dispatch(customerAction.update(values))
                : dispatch(customerAction.create(values));
            } else {
              message.error('Lütfen Verilerinizi Kontrol Edin.');
            }
          });
        }}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('fullName', {
                initialValue: record?.fullName || '',
                rules: [{ required: true, message: 'Lütfen Adınızı Giriniz !' }],
              })(<Input placeholder="Ad" tooltiptitle="Adınız" />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('email', {
                initialValue: record?.email || '',
                rules: [
                  { required: true, message: 'Lütfen E-mail Adresinizi Giriniz !' },
                  { type: 'email', message: 'E-mail adresi girmelisiniz !' },
                  {
                    validator: (_, value) => emailPatternValidate(value),
                    message: 'E-mail adresi geçersiz !',
                  },
                ],
              })(<Input placeholder="E-mail" tooltiptitle="E-mail adresiniz" />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('phone', {
                initialValue: record?.phone || '',
                rules: [{ required: true, message: 'Lütfen Telefon Numaranızı Giriniz!' }],
              })(
                <InputMask mask="0(999)-999-9999">
                  {() => <Input placeholder="Telefon" tooltiptitle="Telefon Numaranız" />}
                </InputMask>,
              )}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('address', {
                initialValue: record?.address,
                rules: [{ required: true, message: 'Lütfen Açık Adresinizi Giriniz!' }],
              })(
                <TextArea
                  autosize={{ minRows: 4, maxRows: 6 }}
                  placeholder="Adres"
                  tooltiptitle="Adresiniz"
                />,
              )}
            </Form.Item>
          </Col>

          <Col offset={5} span={14}>
            <Form.Item>
              <Button
                icon={record ? 'edit' : 'plus-circle'}
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: '#f27a1a',
                  color: '#fff',
                  border: 'none',
                }}
              >
                {record ? 'Güncelle' : 'Kaydet'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'offer' })(AddCustomerModal);
