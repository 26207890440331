import * as customerTypes from './customer.type';

const initialState = {
  customers: [],
  currentCustomer: JSON.parse(localStorage.getItem('currentCustomer')),
  error: null,
  isLoading: false,
  visible: false,
  record: {},
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case `${customerTypes.LIST_CUSTOMER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${customerTypes.LIST_CUSTOMER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        customers: action.payload,
      };
    case `${customerTypes.LIST_CUSTOMER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        customers: null,
      };

    case `${customerTypes.CREATE_CUSTOMER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${customerTypes.CREATE_CUSTOMER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${customerTypes.CREATE_CUSTOMER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${customerTypes.UPDATE_CUSTOMER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${customerTypes.UPDATE_CUSTOMER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${customerTypes.UPDATE_CUSTOMER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${customerTypes.DELETE_CUSTOMER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${customerTypes.DELETE_CUSTOMER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${customerTypes.DELETE_CUSTOMER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${customerTypes.HANDLE_CUSTOMER_ADD_MODAL_VISIBLE}`:
      return {
        ...state,
        visible: !state.visible,
        record: action.payload,
      };

    case `${customerTypes.SET_CURRENT_CUSTOMER}`:
      localStorage.setItem('currentCustomer', JSON.stringify(action.payload));
      return {
        ...state,
        visible: !state.visible,
        currentCustomer: action.payload,
      };

    default:
      return state;
  }
};

export default customerReducer;
