import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeAction } from 'redux/modules';
import { DepotModal } from 'components';
import { Card, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const Box = ({ data, depot, depotPrice, ...otherProps }) => {
  const dispatch = useDispatch();

  return (
    <Card.Grid
      style={{
        width: '7%',
        height: '150px',
        textAlign: 'center',
        margin: 5,
        padding: '15px 1px 1px 1px',
        borderRadius: '10px',
        minWidth: '50px',
      }}
      {...otherProps}
    >
      <>
        <label className="price"> {data.basePrice * data.priceMultiplier} TL</label>

        <div style={{ marginTop: 10 }}>
          <b>
            {depot.blockName}-{data.storeNo}
          </b>
        </div>
        {data.customer && (
          <div style={{ marginTop: 3 }}>
            {data.customer.name}
            <br />
            {data.customer.surname}
          </div>
        )}
        <div style={{ margin: '10px 0px 10px 0px' }}>
          <Switch
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={!depot.adminDisabled}
            /* onChange={e => {
              dispatch(storeAction.updateAdminDisabled(depot));
            }} */
          />
        </div>
      </>
    </Card.Grid>
  );
};

const generateBlockList = (blockKey, storeList) => {
  return storeList.reduce((acc, item) => {
    const isSameBlock = blockKey === item.blockName + ' Blok - ' + (item.front ? 'Ön' : 'Arka');
    if (isSameBlock) return [...acc, item];
    return acc;
  }, []);
};

const Depot = () => {
  const dispatch = useDispatch();
  const { storeList } = useSelector(store => store.store);
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabList, setTabList] = useState(null);
  const [contentList, setContentList] = useState(null);

  // For Modal
  const [selectedDepot, setSelectedDepot] = useState(null);

  const hasData = useMemo(() => tabList && contentList, [tabList, contentList]);

  const generateContentList = useCallback((uniqueDepotKeys, blockList) => {
    return uniqueDepotKeys.reduce((acc, item, index) => {
      const blockDepotsData = blockList[index];

      // Bloğa ait depoları oluşturur
      const mappedBlockDepots = blockDepotsData.map(depotData => (
        <Box
          key={depotData.id}
          data={depotData}
          depot={depotData}
          onClick={() => setSelectedDepot(depotData)}
        />
      ));
      return { ...acc, [item]: mappedBlockDepots };
    }, {});
  }, []);

  useEffect(() => {
    dispatch(storeAction.getAll());
    return () => {};
  }, []);

  useEffect(() => {
    if (!storeList[0]) return;

    const depotKeys = storeList.map(
      item => item.blockName + ' Blok - ' + (item.front ? 'Ön' : 'Arka'),
    );
    const uniqueDepotKeys = Array.from(new Set(depotKeys)).sort();
    const tabList = uniqueDepotKeys.map(item => ({ key: item, tab: item }));

    // 'G', 'H' gibi bloklara göre depoları parçalar
    const blockList = uniqueDepotKeys.map(blockKey => generateBlockList(blockKey, storeList));
    const contentList = generateContentList(uniqueDepotKeys, blockList);

    setSelectedTab(tabList[0].key);
    setTabList(tabList);
    setContentList(contentList);
    return () => {};
  }, [storeList]);

  return (
    <div
      style={{
        background: '#fff',
        minHeight: '280px',
        maxWidth: '1280px',
        margin: 'auto',
        border: 10,
      }}
    >
      <DepotModal
        closeModal={() => setSelectedDepot(null)}
        data={selectedDepot}
        visible={!!selectedDepot}
      />

      <Card
        style={{ width: '100%', borderBottom: 'none' }}
        tabList={tabList}
        activeTabKey={selectedTab}
        onTabChange={key => {
          setSelectedTab(key);
        }}
        loading={!hasData}
      >
        <h3 style={{ marginTop: 50 }}>Üst Kat</h3>
        <div style={{ margin: '0px auto 0px auto' }}>
          {hasData && contentList[selectedTab].map(item => item?.props?.data?.floor && item)}
        </div>
      </Card>
      <br />
      <br />
      <Card style={{ width: '100%', borderTop: 'none', margin: '-60px auto 0px auto' }}>
        <h3>Alt Kat</h3>
        <div style={{ margin: '0px auto 0px auto' }}>
          {hasData && contentList[selectedTab].map(item => !item?.props?.data?.floor && item)}
        </div>
      </Card>
    </div>
  );
};

export default Depot;
