import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ShippingModal } from 'components';
import { Button, Card, Row, Col, message } from 'antd';
import { MinusOutlined, PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';

const { Meta } = Card;

const ServiceCard = ({ addProductToBasket, removeProductFromBasket, product }) => {
  const [shippingModalVisible, setShippingModalVisible] = useState(false);
  const { basketItems } = useSelector(store => store.product);
  const basketProduct = basketItems.filter(item => item.product.id === product.id);

  return (
    <>
      <Card
        style={{ width: '100%', height: 250, borderRadius: '10px' }}
        cover={<img style={{ maxWidth: '6em' }} alt="product" src={product.imageUrl} />}
      >
        <Meta
          title={product.name}
          description={
            <h4 style={{ color: '#f27a1a' }}>
              {product.productType !== 'SHIPPING' ? <div>{`${product.price} TL`}</div> : <br />}
            </h4>
          }
        />
        <Row>
          <Col xl={24} xxl={{ span: 14, offset: 5 }}>
            {product.productType !== 'SHIPPING' ? (
              <div
                style={{
                  border: '1px solid #e8e8e8',
                  borderRadius: 20,
                  marginTop: 25,
                }}
              >
                <MinusOutlined
                  className="counter"
                  onClick={() => {
                    if (basketProduct[0]?.count != undefined) {
                      if (basketProduct[0]?.count - 1 == 0) {
                        removeProductFromBasket(product);
                      } else {
                        addProductToBasket(product, basketProduct[0]?.count - 1);
                      }
                    } else {
                      message.warning('Zaten Sepette Ürün Mevcut Değil');
                    }
                  }}
                  style={{ cursor: 'pointer', float: 'left', marginTop: 5, marginLeft: 10 }}
                />
                <span style={{ margin: 10, fontSize: 15, color: '#f27a1a' }}>
                  {basketProduct[0]?.count || 0}
                </span>
                <PlusOutlined
                  className="counter"
                  onClick={() => addProductToBasket(product, 0)}
                  style={{ cursor: 'pointer', float: 'right', marginTop: 5, marginRight: 10 }}
                />
              </div>
            ) : (
              <div>
                <br />
                <Button onClick={() => setShippingModalVisible(true)}>
                  <h5 style={{ color: '#f27a1a' }}>
                    <ShoppingCartOutlined /> Ekle
                  </h5>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Card>
      <ShippingModal
        visible={shippingModalVisible}
        setModalVisible={() => setShippingModalVisible(true)}
        closeModal={() => setShippingModalVisible(false)}
        product={product}
        addProductToBasket={addProductToBasket}
      />
    </>
  );
};

export default ServiceCard;
