import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productAction, stuffAction } from 'redux/modules';
import { FurnitureSelectPopUp, SelectedListNew } from 'components';
import { Button, Card, Col, Input, message, Row, Select, Typography } from 'antd';
import furnitures from 'constants/furnitures';
import { calculateStuffInfo } from 'utils/utils';

const { Option } = Select;
const { Text } = Typography;
const uuidv4 = require('uuid/v4');

const FurnitureSelect = ({ forward, style, landing = false, width, height, depth }) => {
  const dispatch = useDispatch();

  const { selectedStuffs } = useSelector(store => store.stuff);
  const [currentFurniture, setCurrentFurniture] = useState(furnitures[0]);
  const [furnitureUnits, setFurnitureUnits] = useState(1);
  const [visible, setvisible] = useState(false);

  const { basketItems, products } = useSelector(store => store.product);

  useEffect(() => {
    dispatch(productAction.getAll());
  }, []);

  const stuffListInfo = () => {
    return calculateStuffInfo(selectedStuffs);
  };

  const addProductToBasket = (product, count) => {
    if (basketItems.some(item => item.product.id === product.id)) {
      basketItems.map(item => {
        if (item.product.id === product.id) {
          item.count =
            item.product.productType === 'SHIPPING' ? 1 : count === 0 ? item.count + 1 : count;
          item.product.price = product.price;

          dispatch(productAction.updateBasket(basketItems));
          dispatch(productAction.setBasketTotal(basketItems.reduce(sumTotal, 0)));
          return;
        }
      });
    } else {
      dispatch(productAction.updateBasket([...basketItems, { product, count }]));
      dispatch(
        productAction.setBasketTotal([...basketItems, { product, count }].reduce(sumTotal, 0)),
      );
    }
  };

  function sumTotal(accumulator, item) {
    return accumulator + item.product.price * item.count;
  }

  const addToSelectedStuffs = () =>
    dispatch(
      stuffAction.listSelectedStuffs([
        {
          ...currentFurniture,
          furnitureUnits: furnitureUnits,
          uuid: { uuidv4 },
        },
        ...selectedStuffs,
      ]),
    );

  const closeModal = () => {
    setvisible(false);
  };

  const checkValue = (currentFurniture, currentValue, who) => {
    let filterValues = ['width', 'height', 'depth'];
    filterValues = filterValues.filter(f => f !== who);

    const hasOver280 = filterValues.every(d => currentFurniture[d] >= 280);
    if (hasOver280 && currentValue > 130) {
      return '130 üzerinde değer girilemez!';
    }
  };

  const checkandSet = (value, who) => {
    let numberValue = '';
    if (value) {
      numberValue = Number(value);
      if (Number.isNaN(numberValue)) {
        message.warning('Rakam giriniz.');
        return;
      } else if (numberValue === 0) {
        message.warning('Sıfır giremezsiniz.');
        return;
      } else if (numberValue < 0) {
        message.warning('Eksi değer girilemez..');
        return;
      } else if (numberValue > 290) {
        message.warning('Genişlik 2.9 metreyi geçmemeli.');
        return;
      } else {
        const messageText = checkValue({ ...currentFurniture }, numberValue, who);
        if (messageText) {
          message.warning(messageText);
          return;
        }
      }
    }
    setCurrentFurniture({ ...currentFurniture, [who]: numberValue });
  };
  return (
    <>
      <FurnitureSelectPopUp
        visible={visible}
        closeModal={closeModal}
        addToSelectedStuffs={addToSelectedStuffs}
        offerFurnitures={selectedStuffs}
        width={currentFurniture.width}
        height={currentFurniture.height}
        depth={currentFurniture.depth}
      />

      <Row type="flex" justify="center" gutter={[16, 16]} style={style}>
        <Col sm={24} md={18} lg={12}>
          <Row
            justify="center"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ color: '#f27a1a' }}>Hesaplama Modülü</h2>
            </div>
          </Row>
          {currentFurniture && (
            <Card
              style={{
                borderRadius: 10,
                border: 'none',
                backgroundColor: '#f4f4f4 ',
              }}
              cover={
                <img
                  style={{
                    maxWidth: '22em',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  alt={currentFurniture.name}
                  src={currentFurniture.photo}
                />
              }
            >
              <Row gutter={[16, 16]} style={{ marginTop: 250 }}></Row>
              <Row type="flex" justify="center" gutter={[16, 16]}>
                <Col sm={24} md={24} lg={12}>
                  <Input
                    addonBefore={<div style={{ width: '80px' }}>Adet</div>}
                    min={1}
                    max={1000}
                    value={furnitureUnits}
                    onChange={e => {
                      setFurnitureUnits(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={24} md={24} lg={12}>
                  <Input
                    addonBefore={<div style={{ width: '80px' }}>Genişlik</div>}
                    min={1}
                    max={300}
                    value={currentFurniture.width}
                    onChange={e => checkandSet(e.target.value, 'width')}
                    addonAfter={<div style={{ width: '20px' }}>cm</div>}
                  />
                </Col>

                <Col sm={24} md={24} lg={12}>
                  <Input
                    addonBefore={<div style={{ width: '80px' }}>Derinlik</div>}
                    min={1}
                    max={300}
                    value={currentFurniture.depth}
                    onChange={e => checkandSet(e.target.value, 'depth')}
                    addonAfter={<div style={{ width: '20px' }}>cm</div>}
                  />
                </Col>

                <Col sm={24} md={24} lg={12}>
                  <Input
                    addonBefore={<div style={{ width: '80px' }}>Yükseklik</div>}
                    min={1}
                    max={300}
                    value={currentFurniture.height}
                    onChange={e => checkandSet(e.target.value, 'height')}
                    addonAfter={<div style={{ width: '20px' }}>cm</div>}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center">
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Row type="flex" justify="center" align="middle" gutter={[16, 16]}>
                    <Col md={24} lg={12}>
                      <Button
                        onClick={() => {
                          if (
                            (currentFurniture.width *
                              currentFurniture.height *
                              currentFurniture.depth) /
                              1000000 >
                            9
                          ) {
                            message.warning('Lütfen 9 metreküp üzerine çıkmayınız.');
                          } else {
                            addToSelectedStuffs();
                            message.success('Depoya Başarıyla Eklendi.');
                          }
                        }}
                        type="primary"
                        style={{
                          backgroundColor: '#f27a1a',
                          color: '#fff',
                          marginTop: 47,
                          borderRadius: 20,
                          minWidth: 150,
                          marginRight: 0,
                        }}
                      >
                        Depoma Ekle
                      </Button>
                    </Col>

                    {selectedStuffs && selectedStuffs.length > 0 && (
                      <Col md={24} lg={12}>
                        <Button
                          type="primary"
                          onClick={() => {
                            if (stuffListInfo().safeTotalArea > 0) {
                              addProductToBasket(
                                products.find(product => product.productType == 'STORE'),
                                stuffListInfo().storeUnits,
                              );
                            }
                            forward();
                          }}
                          style={{
                            backgroundColor: '#f27a1a',
                            color: '#fff',
                            marginTop: 47,
                            marginRight: 0,
                            borderRadius: 20,
                            minWidth: 150,
                          }}
                        >
                          {landing ? 'İleri' : 'Boş Depo Kirala'}
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {
                    <Row type="flex" justify="center" align="middle" gutter={[16, 16]}>
                      {landing && (
                        <Col sm={24}>
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: '#293133',
                              color: '#fff',
                              marginTop: 10,
                              borderRadius: 20,
                              minWidth: 150,
                            }}
                            onClick={() => {
                              setvisible(true);
                            }}
                          >
                            {'Teklif Al'}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  }
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[16, 16]}>
                <Col sm={24} md={12} lg={24} style={{ textAlign: 'center', marginTop: 20 }}>
                  <Text
                    style={{
                      fontSize: 15,
                    }}
                  >
                    * Hesaplama Modülü tarafından yapılan hesaplamalar bilgi amaçlı olup, %10
                    güvenlik katsayısı içermektedir.
                  </Text>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        <Col sm={24} md={18} lg={12}>
          <SelectedListNew />
        </Col>
      </Row>
    </>
  );
};

export default FurnitureSelect;
