import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ContactForm,
  ExternalScripts,
  FloatButton,
  Footer,
  Header,
  ServicesSection,
  StoreTypes,
  FurnitureSelect,
  ECommerce,
  Gallery,
} from 'components';

const Landing = () => {
  const history = useHistory();
  const selectedStuffs = useSelector(store => store.stuff.selectedStuffs);

  const forward = () => {
    {
      selectedStuffs &&
        history.push({
          pathname: '/shop',
          isLanding: true,
          state: { returnUrl: '/' },
        });
    }
  };

  return (
    <>
      <ExternalScripts />
      <div>
        <a id="start"></a>
        <Header />
        <div className="main-container">
          <section
            className="cover cover-features height-100 imagebg space--lg parallax"
            data-overlay="8"
            style={{
              backgroundImage: "url('img/banner1.jpg')",
              backgroundSize: 'cover',
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div
                    style={{
                      color: '#f27a1a',
                      fontSize: '5em',
                      marginBottom: '0.2em',
                      minHeight: '1.6em',
                    }}
                  >
                    Depopro
                  </div>
                  <div style={{ fontSize: '3em', color: 'white' }}>Depolama Çözümleri</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6 align-self-center">
                  <a href="#calculate">
                    <div
                      className="feature feature--featured feature-1 boxed boxed--border"
                      style={{ minHeight: 200 }}
                    >
                      <br className="hidden-xs" />
                      <br />
                      <i
                        className="fas fa-calculator"
                        style={{ color: '#f27a1a', fontSize: '30px' }}
                      ></i>
                      <h5 style={{ marginTop: '10px', fontSize: '17px' }}>
                        Depo İhtiyacınızı Hesaplayın
                      </h5>
                    </div>
                  </a>
                </div>

                <div className="col-md-6 col-sm-6 col-6 align-self-center">
                  <a href="#eCommerce">
                    <div
                      className="feature feature--featured feature-1 boxed boxed--border"
                      style={{ minHeight: 200 }}
                    >
                      <br className="hidden-xs" />
                      <br />
                      <i
                        className="far fa-handshake"
                        style={{ color: '#f27a1a', fontSize: '30px' }}
                      ></i>
                      <h5 style={{ marginTop: '10px', fontSize: '17px', marginBottom: '-10px' }}>
                        E-Ticaret Depolama
                      </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <a id="gallery" className="in-page-link"></a>
          <Gallery />
          <div
            style={{
              maxWidth: '100%',
              display: 'flex',
              marginBottom: '30px',
              border: '1px solid #fff',
            }}
            id="eCommerce"
            className="in-page-link"
          ></div>
          <ECommerce />
          <ServicesSection />
          <StoreTypes />
          <div
            style={{
              maxWidth: '100%',
              display: 'flex',
              marginBottom: '30px',
              border: '1px solid #fff',
            }}
            id="calculate"
            className="in-page-link"
          ></div>
          <div>
            <div
              style={{
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '100px',
                marginBottom: '30px',
              }}
            >
              <FurnitureSelect
                style={{ minHeight: '90%', width: '80%' }}
                forward={forward}
                landing
              />
            </div>
          </div>
          <a id="contactForm" className="in-page-link"></a>
          <ContactForm />
          <section className="switchable bg--primary space--sm" id="contact">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-6 col-md-7 col-12">
                  <div className="map-container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.218376197167!2d28.755417351607534!3d41.107921579189046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa8b7b412afd3%3A0x203113b71b352df0!2sMerkez%20Kaya%C5%9Fehir%20Avm!5e0!3m2!1str!2str!4v1604921208066!5m2!1str!2str"
                      width="600"
                      height="450"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen=""
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5">
                  <div className="switchable__text">
                    <h3>
                      Merkez Kayaşehir AVM <br />
                      Kayabaşı Mah. Rabia Caddesi No:159 <br />
                      Başakşehir / İSTANBUL
                    </h3>
                    <p className="lead">
                      E-Mail: <a href="mailto:info@depopro.com.tr">info@depopro.com.tr</a>
                      <br /> Telefon: 0850 450 0 999 <br /> WhatsApp: 0537 475 97 33
                    </p>
                    <p className="lead">
                      İstediğiniz zaman bizi arayın veya çağrı bırakın, tüm sorularınızı 24 saat
                      içinde yanıtlıyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
        <FloatButton />
      </div>
    </>
  );
};

export default Landing;
