import { API } from 'config/configureAxios';
import * as offerTypes from './offer.type';
import { message } from 'antd';

const REQUEST_URL = '/offer';

export const getAll = () => async dispatch => {
  dispatch({ type: offerTypes.LIST_OFFER_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);
    dispatch({
      type: offerTypes.LIST_OFFER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: offerTypes.LIST_OFFER_REJECTED,
      payload: error,
    });
  }
};
export const create = data => async dispatch => {
  dispatch({ type: offerTypes.CREATE_OFFER_PENDING });

  try {
    const response = await API.post(`${REQUEST_URL}`, data);
    dispatch({
      type: offerTypes.CREATE_OFFER_FULFILLED,
      payload: response.data.responseBody,
    });

    if (response.data.responseCode === 200) {
      message.success('Talebiniz alınmıştır. 24 saat içerisinde sizinle iletişime geçeceğiz.');
      dispatch(handleVisible());
    }

    if (response.data.responseCode === 409) {
      message.error(response.data.responseMessage);
    }
    return response;
  } catch (error) {
    dispatch({
      type: offerTypes.CREATE_OFFER_REJECTED,
      payload: error,
    });
    message.error('Bilgilerinizi kontrol ediniz.');
    return error;
  }
};

export const handleVisible = data => dispatch => {
  dispatch({
    type: offerTypes.HANDLE_OFFER_ADD_MODAL_VISIBLE,
    payload: data,
  });
};
