import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { authAction } from 'redux/modules';
import { Button, Card, Form, Icon, Input, message } from 'antd';

const Login = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const authState = useSelector(store => store.auth);

  const { getFieldDecorator, validateFields } = props.form;

  return (
    <>
      {authState.isAuthenticated && history.push('/portal')}
      <div className="center-center" style={{ minHeight: '100vh' }}>
        <Card style={{ width: '400px', margin: 20 }}>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validateFields(err => {
                if (!err) {
                  dispatch(authAction.login(username, password));
                } else {
                  message.error('Kullanıcı Adı veya Şifre Hatalı!');
                }
              });
            }}
          >
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Kullanıcı adı giriniz' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Kullanıcı Adı"
                  onChange={e => setUsername(e.target.value)}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Şifre giriniz' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Şifre"
                  onChange={e => setPassword(e.target.value)}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', backgroundColor: '#f27a1a', color: '#fff', border: 'none' }}
                loading={authState.isLoading}
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Form.create({ name: 'normal_login' })(Login);
