import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AdditionalServicesNew, ShopHeader, ShopFooter } from 'components';
import { Col, Row, Spin } from 'antd';

export const Shop = props => {
  const history = useHistory();
  const isLanding = props?.location?.isLanding;

  const [currentStep, setCurrentStep] = useState(1);
  const [title, setTitle] = useState();
  const [completionRate, setCompletionRate] = useState();

  const { isLoading } = useSelector(store => store.customer);

  const backTo = () => {
    const returnUrl = history?.location?.state?.returnUrl ?? undefined;
    if (currentStep === 1) {
      if (returnUrl && returnUrl === '/#calculate') {
        history.push(returnUrl);
        return;
      } else {
        history.push('/#calculate');
        return;
      }
    }

    setCurrentStep(prev => (prev > 1 ? --prev : prev));
  };

  const forward = () => {
    setCurrentStep(prev => (prev < 4 ? ++prev : prev));
  };

  useEffect(() => {
    isLanding && setCurrentStep(1);
  }, [isLanding]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setTitle('Sipariş Oluşturma');
        setCompletionRate(34);
        return;
      case 2:
        setTitle('Müşteri Bilgileri');
        setCompletionRate(51);
        return;
      case 3:
        setTitle('Ek Hizmetler');
        setCompletionRate(68);
        return;
      case 4:
        setTitle('Sipariş Özeti');
        setCompletionRate(85);
        return;
      default:
        setTitle('Hata');
        setCompletionRate(0);
        return;
    }
  }, [currentStep]);

  return (
    <div style={{ width: '90%', marginTop: '50px', marginRight: 'auto', marginLeft: 'auto' }}>
      <Row justify="center">
        <Col offset={2} span={20}>
          <Row>
            <Col span={24} style={{ marginBottom: 50 }}>
              <ShopHeader title={title} completionRate={completionRate} currentStep={currentStep} />
            </Col>
            <Spin spinning={isLoading} size="large">
              <Col span={24}>
                <AdditionalServicesNew currentStep={currentStep} />
              </Col>
              <Col span={24} style={{ marginTop: 125 }}>
                <ShopFooter backTo={backTo} forward={forward} step={currentStep} />
              </Col>
            </Spin>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Shop;
