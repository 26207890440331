import * as orderTypes from './order.type';

const initialState = {
  orderList: [],
  order: null,
  district: null,
  shippingDate: null,
  error: null,
  isLoading: false,
  visible: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case `${orderTypes.LIST_ORDER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${orderTypes.LIST_ORDER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderList: action.payload,
      };
    case `${orderTypes.LIST_ORDER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        order: null,
      };

    case `${orderTypes.GET_ORDER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${orderTypes.GET_ORDER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        order: action.payload,
      };
    case `${orderTypes.GET_ORDER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        order: null,
      };

    case `${orderTypes.CREATE_ORDER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${orderTypes.CREATE_ORDER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        order: action.payload,
      };
    case `${orderTypes.CREATE_ORDER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        order: null,
      };

    case `${orderTypes.DELETE_ORDER_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${orderTypes.DELETE_ORDER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${orderTypes.DELETE_ORDER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case `${orderTypes.SET_DISTRICT}`:
      return {
        ...state,
        district: action.payload,
      };
    case `${orderTypes.SET_SHIPPING_DATE}`:
      return {
        ...state,
        shippingDate: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
