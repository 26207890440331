import * as paymentTypes from './payment.type';

const initialState = {
  selectedStuffs: [],
  transactionList: null,
  error: null,
  isLoading: false,
  visible: false,
  name: '',
  surname: '',
  link: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${paymentTypes.GET_TRANSACTION_BY_CUSTOMER_PHONE}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${paymentTypes.GET_TRANSACTION_BY_CUSTOMER_PHONE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        transactionList: action.payload.responseBody,
        name: action.payload.responseBody[0] && action.payload.responseBody[0].customer.name,
        surname: action.payload.responseBody[0] && action.payload.responseBody[0].customer.surname,
      };
    case `${paymentTypes.GET_TRANSACTION_BY_CUSTOMER_PHONE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        transactionList: null,
      };
    case `${paymentTypes.PROCEED_PAYMENT}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${paymentTypes.PROCEED_PAYMENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        link: action.payload.responseBody.link,
        // selectedStuffs:  action.payload && action.payload.responseBody.stuffList,
      };
    case `${paymentTypes.PROCEED_PAYMENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        link: '',
      };
    case `${paymentTypes.CLEAR_LOCAL_STORAGE}`:
      localStorage.setItem('selectedStuffs', JSON.stringify([]));
      localStorage.setItem('basketItems', JSON.stringify([]));
      localStorage.setItem('total', 0);
      localStorage.setItem('currentCustomer', null);
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
