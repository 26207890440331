import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ExternalScripts = () => {
  return (
    <HelmetProvider>
      <Helmet
        script={[
          { src: 'js/jquery.maskedinput.js', type: 'text/javascript' },
          { src: 'js/parallax.js', type: 'text/javascript' },
          { src: 'js/smooth-scroll.min.js', type: 'text/javascript' },
          { src: 'js/typed.min.js', type: 'text/javascript' },
          { src: 'js/tawk.to.js', type: 'text/javascript' },
          { src: 'js/scripts.js', type: 'text/javascript' },
          {
            src: 'https://www.googletagmanager.com/gtag/js?id=UA-157332153-2',
            type: 'text/javascript',
          },
          { src: 'js/gtag.js', type: 'text/javascript' },
          { src: 'js/menuFixer.js', type: 'text/javascript' },
        ]}
      ></Helmet>
    </HelmetProvider>
  );
};

export default ExternalScripts;
