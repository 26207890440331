import { Button, Col, DatePicker, Form, Input, InputNumber, message, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import React from 'react';
import { portalAction } from '../../redux/modules';

const { TextArea } = Input;

const EditForm = props => {
  const dispatch = useDispatch();

  const {
    form: { validateFields, getFieldDecorator, resetFields },
  } = props;

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        validateFields((err, values) => {
          if (!err) {
            props.record.amount = +values.amount;
            props.record.paymentEndDate = values.paymentEndDate;
            props.record.notes = [...props.record.notes, { note: values.note }];

            values = {
              ...props.record,
            };

            dispatch(portalAction.updateTransaction(values));
            dispatch(portalAction.getAll());
            resetFields();
          } else {
            message.error(
              'Fatura Oluşturma İşlemi Gerçekleştirilemedi.. Lütfen Bilgilerinizi Kontrol Ediniz.',
            );
          }
        });
      }}
    >
      <Row gutter={24}>
        <Col xs={8}>Aylık Tutar</Col>
        <Col xs={8}>Son Ödeme Tarihi</Col>
        <Col xs={8}>Kiralama Bitiş Tarihi</Col>

        <Col xs={8} style={{ marginTop: 10 }}>
          <Input disabled={true} value={props.record.amount} />
        </Col>
        <Col xs={8} style={{ marginTop: 10 }}>
          <Input
            disabled={true}
            value={DateTime.fromISO(props.record.paymentEndDate).toFormat('dd-MM-yyyy')}
          />
        </Col>
        <Col xs={8} style={{ marginTop: 10 }}>
          <Input
            disabled={true}
            value={DateTime.fromISO(props.record.contractEndDate).toFormat('dd-MM-yyyy')}
          />
        </Col>

        <Col xs={8} style={{ marginTop: 25 }}>
          <Form.Item>
            {getFieldDecorator('amount', {
              rules: [{ required: true, message: 'Lütfen Aylık Tutar Giriniz!' }],
            })(<InputNumber style={{ width: '100%' }} placeholder="Yeni Tutar" />)}
          </Form.Item>
        </Col>
        <Col xs={16} style={{ marginTop: 25 }}>
          <Form.Item>
            {getFieldDecorator('paymentEndDate', {
              rules: [{ required: true, message: 'Lütfen Son Ödeme Tarihini Giriniz !' }],
            })(
              <DatePicker
                style={{ width: '100%', paddingLeft: 5 }}
                placeholder="Son Ödeme Tarihi"
                format={'L'}
                locale={locale}
                format="DD-MM-YYYY"
              />,
            )}
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item>{getFieldDecorator('note', {})(<TextArea placeholder="Notlar" />)}</Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: '#f27a1a',
                color: '#000',
                border: 'none',
              }}
            >
              Kaydet
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({ name: 'edit' })(EditForm);
