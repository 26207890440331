import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { paymentAction } from 'redux/modules';
import { Button, Card, Col, message, Row } from 'antd';

const Payment = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');

  const onSearchPhone = () => {
    if (!phone || phone.length !== 11) {
      message.warning('Lütfen Numaranızı Kontrol Edin.');
    } else {
      dispatch(paymentAction.listByCustomerPhone(phone));
    }
  };

  const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      return onSearchPhone();
    }
  };

  return (
    <>
      <Row type="flex" justify="center" style={{ paddingTop: 20 }}>
        <Col span={24} style={{ textAlign: 'center' }} xs={24} sm={16} md={18} lg={16} xl={16}>
          <a href="https://depopro.com.tr/">
            <img src="https://depopro.com.tr/img/depopro_logo.png" alt="DepoPro Logo" width={350} />
          </a>
        </Col>
      </Row>

      <Row type="flex" justify="center" style={{ padding: 20 }}>
        <Col span={10} style={{ textAlign: 'center' }} xs={24} sm={24} md={18} lg={16} xl={12}>
          <Card style={{ borderRadius: 10 }}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <InputMask
                  onChange={e =>
                    setPhone(
                      e.target.value
                        .split('-')
                        .join('')
                        .split('_')
                        .join(''),
                    )
                  }
                  style={{ width: '100%', lineHeight: 1, padding: '5px 10px' }}
                  mask="0(999)-999-99-99"
                  placeholder="Telefon"
                  tooltiptitle="Telefon numaranızı giriniz"
                  onKeyDown={onKeyDownHandler}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => onSearchPhone()}
                  style={{
                    backgroundColor: '#f27a1a',
                    color: '#000',
                    border: 'none',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: '0px',
                    height: '34px',
                  }}
                >
                  Listele
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="center"
        style={{ width: '80%', display: 'block', marginRight: 'auto', marginLeft: 'auto' }}
      >
      </Row>
    </>
  );
};

export default Payment;
