import React, { useEffect, useState } from 'react';
import { customerAction } from 'redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { ExcelExportCustomers } from 'components';
import AddCustomerModal from './AddCustomerModal';
import { Button, Col, Icon, Input, Row, Popconfirm, Table, Tooltip } from 'antd';
import { searchByTextInArray } from 'utils/utils';

const Customer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [filteredCustomer, setfilteredCustomer] = useState([]);
  const { customers } = useSelector(store => store.customer);

  useEffect(() => {
    dispatch(customerAction.getAll());
  }, []);

  useEffect(() => {
    const filteredList = searchByTextInArray(customers, searchKey);
    setfilteredCustomer(filteredList);
  }, [searchKey]);

  const columns = [
    {
      title: 'Ad Soyad',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'İşlem',
      key: 'edit',
      align: 'center',
      render: record => (
        <div style={{ color: '#1890ff' }}>
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Col span={12}>
              <Tooltip title="Düzenle" placement="top">
                <Icon
                  type="edit"
                  onClick={() => {
                    dispatch(customerAction.handleVisible(record));
                  }}
                />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Müşteriyi silmek istediğinizden emin misiniz?"
                onConfirm={() => {
                  dispatch(customerAction.deleteCustomer(record));
                }}
                onCancel={() => {}}
                okText="Evet"
                cancelText="Hayır"
              >
                <Tooltip title="Kaldır" placement="top">
                  <Icon type="delete" style={{ color: 'red', cursor: 'pointer' }} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row justify="center" gutter={[8, 16]} style={{ textAlign: 'center' }}>
        <Col sm={24} md={18}>
          <Input placeholder="Ara..." onChange={e => setSearchKey(e.target.value)} />
        </Col>
        <Col sm={12} md={3}>
          <Button
            type="primary"
            icon="plus-circle"
            onClick={() => dispatch(customerAction.handleVisible())}
            style={{ backgroundColor: '#f27a1a', color: '#fff', width: '100%' }}
          >
            Yeni
          </Button>
        </Col>
        <Col sm={12} md={3}>
          <ExcelExportCustomers customers={customers} />
        </Col>
        <Col span={24}>
          <Table
            size={'small'}
            dataSource={
              searchKey == '' && filteredCustomer.length < 1 ? customers : filteredCustomer
            }
            rowKey={(record, index) => index}
            pagination={{ pageSize: 10 }}
            columns={columns}
            expandedRowRender={record => (
              <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <b>Adres: </b> {record.address}
              </div>
            )}
          />
        </Col>
      </Row>
      <AddCustomerModal />
    </>
  );
};

export default Customer;
