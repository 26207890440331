import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { PrivateRoute } from '../components';

// Pages
import Landing from './Landing/Landing';
import Stores from './Landing/Stores';
import Login from './Login/Login';
import Payment from './Payment/Payment';
import Portal from './Portal/Portal';
import Shop from './Shop/Shop';
import Success from './Success/Success';
import Error from './Error/Error';
import faq from './Faq/faq';

const Router = () => {
  const isAuthenticated = useSelector(store => store.auth.isAuthenticated);

  return (
    <Switch>
      <Route path="/faq" component={faq} exact />
      <Route path="/" component={Landing} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/pay" component={Payment} exact />
      <Route path="/shop" component={Shop} exact />
      <Route path="/stores" component={Stores} exact />
      <Route path="/success" component={Success} exact />
      <Route path="/error" component={Error} exact />
      <PrivateRoute path="/portal" component={Portal} exact isAuthenticated={isAuthenticated} />

      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  );
};

export default withRouter(Router);
