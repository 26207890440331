export const LIST_ORDER_PENDING = 'LIST_ORDER_PENDING';
export const LIST_ORDER_FULFILLED = 'LIST_ORDER_FULFILLED';
export const LIST_ORDER_REJECTED = 'LIST_ORDER_REJECTED';

export const GET_ORDER_PENDING = 'GET_ORDER_PENDING';
export const GET_ORDER_FULFILLED = 'GET_ORDER_FULFILLED';
export const GET_ORDER_REJECTED = 'GET_ORDER_REJECTED';

export const GET_ORDER_BY_CUSTOMER_EMAIL_PENDING = 'GET_ORDER_BY_CUSTOMER_EMAIL_PENDING';
export const GET_ORDER_BY_CUSTOMER_EMAIL_FULFILLED = 'GET_ORDER_BY_CUSTOMER_EMAIL_FULFILLED';
export const GET_ORDER_BY_CUSTOMER_EMAIL_REJECTED = 'GET_ORDER_BY_CUSTOMER_EMAIL_REJECTED';

export const CREATE_ORDER_PENDING = 'CREATE_ORDER_PENDING';
export const CREATE_ORDER_FULFILLED = 'CREATE_ORDER_FULFILLED';
export const CREATE_ORDER_REJECTED = 'CREATE_ORDER_REJECTED';

/* export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING';
export const UPDATE_ORDER_FULFILLED = 'UPDATE_ORDER_FULFILLED';
export const UPDATE_ORDER_REJECTED = 'UPDATE_ORDER_REJECTED'; */

export const DELETE_ORDER_PENDING = 'DELETE_ORDER_PENDING';
export const DELETE_ORDER_FULFILLED = 'DELETE_ORDER_FULFILLED';
export const DELETE_ORDER_REJECTED = 'DELETE_ORDER_REJECTED';

export const SET_DISTRICT = 'SET_DISTRICT';
export const SET_SHIPPING_DATE = 'SET_SHIPPING_DATE';
