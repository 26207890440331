import { API } from 'config/configureAxios';
import * as storeTypes from './store.type';

const REQUEST_URL = '/store';

export const getAll = () => async dispatch => {
  dispatch({ type: storeTypes.LIST_STORE_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);
    dispatch({
      type: storeTypes.LIST_STORE_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: storeTypes.LIST_STORE_REJECTED,
      payload: error,
    });
  }
};
