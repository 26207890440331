import { message } from 'antd';
import { API, urlEncodedAPI } from 'config/configureAxios';
import { formUrlEncoded } from 'utils/utils';
import * as authTypes from './auth.type';

const config = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

export const login = (username, password) => async dispatch => {
  dispatch({ type: authTypes.LOGIN_PENDING });

  const data = formUrlEncoded({ username, password });
  try {
    const response = await urlEncodedAPI.post(`/login`, data, config);
    dispatch({ type: authTypes.LOGIN_FULFILLED, payload: response.data });
  } catch (error) {
    dispatch({ type: authTypes.LOGIN_REJECTED, payload: error });
    message.error('Kullanıcı Adı veya Şifre Hatalı!');
  }
};

export const self = () => async dispatch => {
  dispatch({ type: authTypes.SELF_PENDING });
  try {
    const response = await API.get(`/user/self`);
    dispatch({ type: authTypes.SELF_FULFILLED, payload: response.data });

    if (response.data.responseCode === 401) {
      dispatch({ type: authTypes.SELF_REJECTED, payload: response.data.responseCode });
      message.error('Oturumu Süreniz Dolmuştur.');
    }
  } catch (error) {
    dispatch({ type: authTypes.SELF_REJECTED, payload: error });
    message.error('Kimlik doğrulaması başarısız.');
  }
};

export const logout = () => async dispatch => {
  urlEncodedAPI.post(`/logout`);
  dispatch({ type: authTypes.LOGOUT });
  message.success('Başarıyla Çıkış Yapıldı.');
};
