import { message } from 'antd';
import { API } from 'config/configureAxios';
import * as orderTypes from './order.type';
import { paymentAction } from 'redux/modules';

const REQUEST_URL = '/orders';

export const list = () => async dispatch => {
  dispatch({ type: orderTypes.LIST_ORDER_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);
    dispatch({
      type: orderTypes.LIST_ORDER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: orderTypes.LIST_ORDER_REJECTED,
      payload: error,
    });
  }
};

export const get = id => async dispatch => {
  dispatch({ type: orderTypes.GET_ORDER_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/` + id);
    dispatch({
      type: orderTypes.GET_ORDER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: orderTypes.GET_ORDER_REJECTED,
      payload: error,
    });
  }
};

export const create = data => async dispatch => {
  dispatch({ type: orderTypes.CREATE_ORDER_PENDING });

  try {
    const response = await API.post(`${REQUEST_URL}`, data);
    dispatch({
      type: orderTypes.CREATE_ORDER_FULFILLED,
      payload: response.data.responseBody,
    });
    if (response.data.responseCode === 200) {
      dispatch(list());
      message.success('Siparişiniz Başarıyla Oluşturuldu.');
      dispatch(paymentAction.proceedPayment({ order: response.data.responseBody }));
    }
  } catch (error) {
    dispatch({
      type: orderTypes.CREATE_ORDER_REJECTED,
      payload: error,
    });
    message.error('Siparişiniz Oluşturulurken Bir Hata Oluştu.');
  }
};

export const deleteOrder = data => async dispatch => {
  dispatch({ type: orderTypes.DELETE_ORDER_PENDING });

  try {
    const response = await API.delete(`${REQUEST_URL}` + data.id);
    dispatch({
      type: orderTypes.DELETE_ORDER_FULFILLED,
      payload: response.data,
    });

    if (response.data.responseCode === 200) {
      dispatch(list());
      message.success('Müşteri Başarıyla Silindi.');
    }
  } catch (error) {
    dispatch({
      type: orderTypes.DELETE_ORDER_REJECTED,
      payload: error,
    });
    message.error('Sipariş Silme İşlemi Gerçekleştirilemedi.');
  }
};

export const setDistrict = data => dispatch => {
  dispatch({
    type: orderTypes.SET_DISTRICT,
    payload: data,
  });
};

export const setShippingDate = data => dispatch => {
  dispatch({
    type: orderTypes.SET_SHIPPING_DATE,
    payload: data,
  });
};
