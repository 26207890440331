import { message } from 'antd';
import { API, multipartFileAPI } from 'config/configureAxios';
import * as productTypes from './product.type';

const REQUEST_URL = '/product';

export const getAll = () => async dispatch => {
  dispatch({ type: productTypes.LIST_PRODUCT_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);
    dispatch({
      type: productTypes.LIST_PRODUCT_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: productTypes.LIST_PRODUCT_REJECTED,
      payload: error,
    });
  }
};

export const get = id => async dispatch => {
  dispatch({ type: productTypes.GET_PRODUCT_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/` + id);
    dispatch({
      type: productTypes.GET_PRODUCT_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: productTypes.GET_PRODUCT_REJECTED,
      payload: error,
    });
  }
};

export const create = data => async dispatch => {
  dispatch({ type: productTypes.CREATE_PRODUCT_PENDING });

  try {
    const response = await API.post(`${REQUEST_URL}`, data);
    dispatch({
      type: productTypes.CREATE_PRODUCT_FULFILLED,
      payload: response.data.responseBody,
    });

    if (response.data.responseCode === 200) {
      dispatch(getAll());
      message.success('Ürün başarıyla oluşturuldu.');
    }
    return response;
  } catch (error) {
    dispatch({
      type: productTypes.CREATE_PRODUCT_REJECTED,
      payload: error,
    });
    message.error('Ürün oluşturma işlemi gerçekleştirilemedi.');
    return error;
  }
};

export const update = data => async dispatch => {
  dispatch({ type: productTypes.UPDATE_PRODUCT_PENDING });

  try {
    const response = await API.put(`${REQUEST_URL}`, data);
    dispatch({
      type: productTypes.UPDATE_PRODUCT_FULFILLED,
      payload: response.data,
    });

    if (response.data.responseCode === 200) {
      dispatch(getAll());
      message.success('Ürün Başarıyla Güncellendi.');
    }
  } catch (error) {
    dispatch({
      type: productTypes.UPDATE_PRODUCT_REJECTED,
      payload: error,
    });
    message.error('Ürün güncelleme işlemi gerçekleştirilemedi.');
  }
};

export const deleteProduct = data => async dispatch => {
  dispatch({ type: productTypes.DELETE_PRODUCT_PENDING });

  try {
    const response = await API.delete(`${REQUEST_URL}/` + data.id);
    dispatch({
      type: productTypes.DELETE_PRODUCT_FULFILLED,
      payload: response.data,
    });

    if (response.data.responseCode === 200) {
      dispatch(getAll());
      message.success('Ürün Başarıyla Silindi.');
    }
  } catch (error) {
    dispatch({
      type: productTypes.DELETE_PRODUCT_REJECTED,
      payload: error,
    });
    message.error('Ürün silme işlemi gerçekleştirilemedi.');
  }
};

export const imageUpload = image => async dispatch => {
  dispatch({ type: productTypes.UPLOAD_PRODUCT_IMAGE_PENDING });

  try {
    const formData = new FormData();
    formData.append('image', image);
    const response = await multipartFileAPI.post('/file/uploadImage', formData);

    dispatch({
      type: productTypes.UPLOAD_PRODUCT_IMAGE_FULFILLED,
      payload: response.data,
    });

    message.success('Resim yükleme işlemi başarılı.');
  } catch (error) {
    dispatch({
      type: productTypes.UPLOAD_PRODUCT_IMAGE_REJECTED,
      payload: error,
    });
    message.error('Resim yükleme işlemi gerçekleştirilemedi.');
  }
};

export const handleVisibleProductModal = data => dispatch => {
  dispatch({
    type: productTypes.HANDLE_PRODUCT_ADD_MODAL_VISIBLE,
    payload: data,
  });
};

export const handleVisibleDistrictModal = data => dispatch => {
  dispatch({
    type: productTypes.HANDLE_DISTRICT_ADD_MODAL_VISIBLE,
    payload: data,
  });
};

export const updateBasket = data => dispatch => {
  dispatch({
    type: productTypes.UPDATE_BASKET,
    payload: data,
  });
};

export const setBasketTotal = data => dispatch => {
  dispatch({
    type: productTypes.SET_BASKET_TOTAL,
    payload: data,
  });
};
