import * as stuffTypes from './stuff.type';

const initialState = {
  selectedStuffs: JSON.parse(localStorage.getItem('selectedStuffs')) || [],
  error: null,
  isLoading: false,
  visible: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case `${stuffTypes.LIST_SELECTED_STUFFS}`:
      localStorage.setItem('selectedStuffs', JSON.stringify(action.payload));
      return {
        ...state,
        selectedStuffs: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
