import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productAction, shippingAction } from 'redux/modules';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
} from 'antd';

const AddDistrictModal = props => {
  const dispatch = useDispatch();

  const [shippingRecord, setShippingRecord] = useState(null);
  const { shippingPriceList } = useSelector(state => state.shipping);
  const { visible } = useSelector(({ product }) => ({
    visible: product.districtModalVisible,
  }));

  const {
    form: { validateFields, getFieldDecorator, resetFields },
  } = props;

  useEffect(() => {
    dispatch(shippingAction.list());
  }, []);

  const columns = [
    {
      title: 'İlçe',
      dataIndex: 'district',
      key: 'district',
      width: '40%',
      sorter: (a, b) => a.district.localeCompare(b.district),
    },
    {
      title: 'Fiyat (₺)',
      dataIndex: 'districtPrice',
      key: 'districtPrice',
      align: 'center',
      width: '20%',
    },
    {
      title: 'İşlem',
      key: 'edit',
      align: 'center',
      width: '40%',
      exportToExcel: false,
      render: record => (
        <div style={{ color: '#1890ff' }}>
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Col span={12}>
              <Tooltip title="Düzenle!" placement="top">
                <Icon
                  type="edit"
                  onClick={() => {
                    setShippingRecord(record);
                  }}
                />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Ürünü silmek istediğinizden emin misiniz?"
                onConfirm={() => {
                  dispatch(shippingAction.deleteShipping(record.id));
                  setShippingRecord(null);
                }}
                onCancel={() => {}}
                okText="Evet"
                cancelText="Hayır"
              >
                <Tooltip title="Kaldır" placement="top">
                  <Icon type="delete" style={{ color: 'red', cursor: 'pointer' }} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const handleVisible = () => {
    dispatch(productAction.handleVisibleDistrictModal());
  };

  return (
    <Modal
      destroyOnClose={true}
      title="DepoPro"
      visible={visible}
      onOk={handleVisible}
      onCancel={handleVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      centered={true}
      width={window?.screen?.width < 1200 ? '75%' : '30%'}
    >
      <Form
        onSubmit={event => {
          event.preventDefault();
          validateFields((err, values) => {
            if (!err) {
              values = {
                ...values,
                id: shippingRecord?.id || null,
              };

              shippingRecord
                ? dispatch(shippingAction.update(values))
                : dispatch(shippingAction.create(values));
              resetFields();
              setShippingRecord(null);
            } else {
              message.error('Lütfen Verilerinizi Kontrol Edin 😞');
            }
          });
        }}
      >
        <Row gutter={8}>
          <Col md={24} lg={9}>
            <Form.Item>
              {getFieldDecorator('district', {
                initialValue: shippingRecord?.district || '',
                rules: [{ required: true, message: 'Lütfen İlçe Adını Giriniz !' }],
              })(<Input placeholder="İlçe Adı" tooltiptitle="İlçe Adı" />)}
            </Form.Item>
          </Col>
          <Col md={24} lg={9}>
            <Form.Item>
              {getFieldDecorator('districtPrice', {
                initialValue: shippingRecord?.districtPrice || '',
                rules: [{ required: true, message: 'Lütfen Fiyat Giriniz !' }],
              })(
                <InputNumber
                  min={0}
                  placeholder="Fiyat"
                  tooltiptitle="Fiyat"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item>
              <Row>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: '#f27a1a',
                    color: '#fff',
                    border: 'none',
                  }}
                >
                  {shippingRecord ? 'Güncelle' : 'Oluştur'}
                </Button>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        size={'small'}
        dataSource={shippingPriceList}
        pagination={false}
        columns={columns}
        rowKey={(record, index) => index}
      />
    </Modal>
  );
};

export default Form.create({ name: 'shipping' })(AddDistrictModal);
