import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderAction } from 'redux/modules';
import { searchByTextInObject } from 'utils/utils';
import { Input, Col, Row, Table } from 'antd';
import moment from 'moment';

const OrdersTable = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const { orderList } = useSelector(store => store.order);
  const [filteredOrder, setfilteredOrder] = useState([]);

  useEffect(() => {
    dispatch(orderAction.list());
  }, []);

  useEffect(() => {
    const filteredList = searchByTextInObject(orderList, 'customer', searchKey);
    setfilteredOrder(filteredList);
  }, [searchKey]);

  const columns = [
    {
      title: 'Tarih',
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      render: text => {
        return moment(text).format('LLL');
      },
    },
    {
      title: 'Müşteri Adı Soyadı',
      render: record => record.customer?.fullName,
    },
    {
      title: 'Nakliye Adresi',
      align: 'center',
      render: record => record.district || '-',
    },
    {
      title: 'Toplam Tutar',
      align: 'center',
      render: record => `${record.totalPrice.toFixed(2)} TL`,
    },

    /* {
      title: 'Güncelle',
      render: record => (
        <div
          style={{ color: '#1890ff', cursor: 'pointer', textAlign: 'center', fontSize: 20 }}
          onClick={() => {
            dispatch(portalAction.handleVisibleEditModal(record));
          }}
        >
          <Icon type="reload" />
        </div>
      ),
    }, */
    /* {
      title: 'Öde',
      render: record => (
        <Popconfirm
          title="Ödeme Yapmak istediğinize Emin Misiniz?"
          okText={record.paid === false ? 'Ödeme Yap' : 'Ödemeyi Sil'}
          cancelText="İptal"
          onConfirm={() => {
            record = {
              ...record,
              paid: !record.paid,
            };
            updateTransactionPaid(record);
          }}
        >
          <Checkbox checked={record.paid}>Ödeme Yapıldı</Checkbox>
        </Popconfirm>
      ),
    }, */
  ];

  const productListColumns = [
    {
      title: 'Ürün',
      dataIndex: 'product.name',
      key: 'product.name',
      width: '25%',
    },
    {
      title: 'Ürün Açıklaması',
      dataIndex: 'product.description',
      key: 'product.description',
      width: '45%',
    },
    {
      title: 'Adet',
      dataIndex: 'count',
      key: 'count',
      width: '15%',
      // TODO: Burada adet shipping için adet yerine district yazılabilir.
    },
    {
      title: 'Fiyat',
      dataIndex: 'product.price',
      key: 'product.price',
      render: record => record + ' TL',
      width: '15%',
    },
  ];

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col>
          <Input placeholder="Ara..." onChange={e => setSearchKey(e.target.value)} />
        </Col>
        <Col>
          <Table
            size={'small'}
            dataSource={searchKey == '' && filteredOrder.length < 1 ? orderList : filteredOrder}
            columns={columns}
            rowKey={(item, index) => index}
            expandedRowRender={record => (
              <>
                {record.productList.length > 0 && (
                  <Table
                    size={'small'}
                    columns={productListColumns}
                    dataSource={record.productList}
                    pagination={false}
                  />
                )}
              </>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrdersTable;
