import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { offerAction } from 'redux/modules';
import { Col, Row, Table, Input } from 'antd';
import { searchByTextInArray } from 'utils/utils';
import moment from 'moment';

const columns = [
  {
    title: 'Ad Soyad',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Teklif Tarihi',
    dataIndex: 'created',
    key: 'created',
    render: record => moment(record).format('LLL'),
  },
];

const Offer = () => {
  const dispatch = useDispatch();

  const { offers } = useSelector(store => store.offer);
  const [searchKey, setSearchKey] = useState('');
  const [filteredOffer, setfilteredOffer] = useState([]);

  useEffect(() => {
    dispatch(offerAction.getAll());
  }, []);

  useEffect(() => {
    const filteredList = searchByTextInArray(offers, searchKey);
    setfilteredOffer(filteredList);
  }, [searchKey]);

  return (
    <>
      <Row justify="center" gutter={[8, 16]} style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Input placeholder="Ara..." onChange={e => setSearchKey(e.target.value)} />
        </Col>
        <Col span={24}>
          <Table
            size={'small'}
            dataSource={searchKey == '' && filteredOffer.length < 1 ? offers : filteredOffer}
            columns={columns}
            rowKey={(item, index) => index}
          />
        </Col>
      </Row>
    </>
  );
};

export default Offer;
