import { message } from 'antd';
import { API } from 'config/configureAxios';
import { shippingAction } from 'redux/modules';
import * as shippingTypes from './shipping.type';

const REQUEST_URL = '/shipping';

export const list = () => async dispatch => {
  dispatch({ type: shippingTypes.LIST_SHIPPING_PRICE_MULTIPLIER_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}`);

    dispatch({
      type: shippingTypes.LIST_SHIPPING_PRICE_MULTIPLIER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: shippingTypes.LIST_SHIPPING_PRICE_MULTIPLIER_REJECTED,
      payload: error,
    });
  }
};

export const get = id => async dispatch => {
  dispatch({ type: shippingTypes.GET_SHIPPING_PRICE_MULTIPLIER_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/${id}`);
    dispatch({
      type: shippingTypes.GET_SHIPPING_PRICE_MULTIPLIER_FULFILLED,
      payload: response.data.responseBody,
    });
  } catch (error) {
    dispatch({
      type: shippingTypes.GET_SHIPPING_PRICE_MULTIPLIER_REJECTED,
      payload: error,
    });
  }
};

export const create = data => async dispatch => {
  dispatch({ type: shippingTypes.CREATE_SHIPPING_PRICE_MULTIPLIER_PENDING });

  try {
    const response = await API.post(`${REQUEST_URL}`, data);
    dispatch({
      type: shippingTypes.CREATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED,
      payload: response.data.responseBody,
    });

    if (response.data.responseCode === 200) {
      dispatch(shippingAction.list());
      message.success('Sipariş Ücreti Başarıyla Güncellendi.');
    }
  } catch (error) {
    dispatch({
      type: shippingTypes.CREATE_SHIPPING_PRICE_MULTIPLIER_REJECTED,
      payload: error,
    });
    message.error('Sipariş ücreti güncelleme işlemi başarısız.');
  }
};

export const update = data => async dispatch => {
  dispatch({ type: shippingTypes.UPDATE_SHIPPING_PRICE_MULTIPLIER_PENDING });

  try {
    const response = await API.put(`${REQUEST_URL}`, data);
    dispatch({
      type: shippingTypes.UPDATE_SHIPPING_PRICE_MULTIPLIER_FULFILLED,
      payload: response.data.responseBody,
    });

    if (response.data.responseCode === 200) {
      dispatch(list());
      message.success('Sipariş Ücreti Başarıyla Güncellendi.');
    }
  } catch (error) {
    dispatch({
      type: shippingTypes.UPDATE_SHIPPING_PRICE_MULTIPLIER_REJECTED,
      payload: error,
    });
    message.error('Sipariş ücreti güncelleme işlemi başarısız.');
  }
};

export const deleteShipping = id => async dispatch => {
  dispatch({ type: shippingTypes.DELETE_SHIPPING_PRICE_MULTIPLIER_PENDING });

  try {
    const response = await API.delete(`${REQUEST_URL}/${id}`);
    dispatch({
      type: shippingTypes.DELETE_SHIPPING_PRICE_MULTIPLIER_FULFILLED,
      payload: response.data,
    });

    if (response.data.responseCode === 200) {
      dispatch(list());
      message.success('Ürün Başarıyla Silindi.');
    }
  } catch (error) {
    dispatch({
      type: shippingTypes.DELETE_SHIPPING_PRICE_MULTIPLIER_REJECTED,
      payload: error,
    });
    message.error('Ürün silme işlemi başarısız!');
  }
};
