import { API } from 'config/configureAxios';
import * as paymentTypes from './payment.type';

const REQUEST_URL_PAYMENT = '/pos';

export const proceedPayment = model => async dispatch => {
  dispatch({ type: paymentTypes.PROCEED_PAYMENT });
  try {
    const response = await API.post(`${REQUEST_URL_PAYMENT}/start`, model);

    dispatch({
      type: paymentTypes.PROCEED_PAYMENT_FULFILLED,
      payload: response.data,
    });
    window.location = response.data.responseBody;
  } catch (error) {
    dispatch({
      type: paymentTypes.PROCEED_PAYMENT_REJECTED,
      payload: error,
    });
  }
};

export const clearLocalStorage = () => {
  return {
    type: paymentTypes.CLEAR_LOCAL_STORAGE,
    payload: '',
  };
};
