export const LIST_CUSTOMER_PENDING = 'LIST_CUSTOMER_PENDING';
export const LIST_CUSTOMER_FULFILLED = 'LIST_CUSTOMER_FULFILLED';
export const LIST_CUSTOMER_REJECTED = 'LIST_CUSTOMER_REJECTED';

export const GET_CUSTOMER_PENDING = 'GET_CUSTOMER_PENDING';
export const GET_CUSTOMER_FULFILLED = 'GET_CUSTOMER_FULFILLED';
export const GET_CUSTOMER_REJECTED = 'GET_CUSTOMER_REJECTED';

export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING';
export const CREATE_CUSTOMER_FULFILLED = 'CREATE_CUSTOMER_FULFILLED';
export const CREATE_CUSTOMER_REJECTED = 'CREATE_CUSTOMER_REJECTED';

export const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING';
export const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED';
export const UPDATE_CUSTOMER_REJECTED = 'UPDATE_CUSTOMER_REJECTED';

export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING';
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED';
export const DELETE_CUSTOMER_REJECTED = 'DELETE_CUSTOMER_REJECTED';

export const HANDLE_CUSTOMER_ADD_MODAL_VISIBLE = 'HANDLE_CUSTOMER_ADD_MODAL_VISIBLE';

export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
