import * as portalTypes from './portal.type';

export const setActiveTabKey = key => ({
  type: portalTypes.SET_PORTAL_ACTIVE_TAB_KEY,
  payload: key,
});

/* export const setSelectedStoresForLeasingForm = stores => ({
  type: portalTypes.SET_SELECTED_STORE_FOR_LEASING_FORM,
  payload: stores,
}); */

export const handleVisibleEditModal = record => ({
  type: portalTypes.HANDLE_VISIBLE_EDITMODAL,
  payload: record,
});
