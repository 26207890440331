import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerAction } from 'redux/modules';
import InputMask from 'react-input-mask';
import { Form, Input, message, Col, Row } from 'antd';
import { emailPatternValidate } from 'utils/utils';

const { TextArea } = Input;

const CustomerRegister = props => {
  const dispatch = useDispatch();
  const { currentCustomer } = useSelector(store => store.customer);
  const customerRegisterFormRef = useRef(null);
  window.customerRegisterFormRef = customerRegisterFormRef;

  const {
    form: { validateFields, getFieldDecorator, resetFields },
  } = props;

  return (
    <>
      <Row justify="center">
        <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>
          <Form
            ref={customerRegisterFormRef}
            style={{
              width: 'inherit',
              border: '1px solid #e8e8e8',
              padding: '24px 24px 0px 24px',
              borderRadius: 10,
            }}
            onSubmit={(e, forward) => {
              return validateFields(async (err, values) => {
                if (!err) {
                  dispatch(customerAction.setCurrentCustomer(values));
                  forward();
                  return true;
                } else {
                  message.error('Lütfen Verilerinizi Kontrol Edin.');
                  return false;
                }
              });
            }}
          >
            <Form.Item>
              {getFieldDecorator('fullName', {
                initialValue: currentCustomer?.fullName,
                rules: [{ required: true, message: 'Lütfen Ad Soyad Giriniz!' }],
              })(<Input placeholder="Ad Soyad" tooltiptitle="Adınız Soyadınız" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('email', {
                initialValue: currentCustomer?.email,
                rules: [
                  { required: true, message: 'Lütfen Email Adresinizi Giriniz!', type: 'email' },
                  {
                    validator: (_, value) => emailPatternValidate(value),
                    message: 'E-mail adresi geçersiz !',
                  },
                ],
              })(<Input placeholder="Email" tooltiptitle="Email Adresiniz" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phone', {
                initialValue: currentCustomer?.phone,
                rules: [{ required: true, message: 'Lütfen Telefon Numaranızı Giriniz!' }],
              })(
                <InputMask mask="0(999)-999-9999">
                  {() => <Input placeholder="Telefon" tooltiptitle="Telefon Numaranız" />}
                </InputMask>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('address', {
                initialValue: currentCustomer?.address,
                rules: [{ required: true, message: 'Lütfen Açık Adresinizi Giriniz!' }],
              })(
                <TextArea
                  autosize={{ minRows: 4, maxRows: 6 }}
                  placeholder="Adres"
                  tooltiptitle="Adresiniz"
                />,
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Form.create({ name: 'customer-register' })(CustomerRegister);
