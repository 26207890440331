import * as portalTypes from './portal.type';

const initialState = {
  activeTabKey: '1',
  visibleEditModal: false,
  record: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${portalTypes.SET_PORTAL_ACTIVE_TAB_KEY}`:
      return {
        ...state,
        activeTabKey: action.payload,
      };
    case `${portalTypes.HANDLE_VISIBLE_EDITMODAL}`:
      return {
        ...state,
        visibleEditModal: !state.visibleEditModal,
        record: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
