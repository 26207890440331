import * as productTypes from './product.type';

const initialState = {
  products: [],
  product: null,
  image: null,
  basketItems: JSON.parse(localStorage.getItem('basketItems')) || [],
  error: null,
  isLoading: false,
  productModalVisible: false,
  districtModalVisible: false,
  total: JSON.parse(localStorage.getItem('total')) || 0,
  record: {},
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case `${productTypes.LIST_PRODUCT_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${productTypes.LIST_PRODUCT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      };
    case `${productTypes.LIST_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case `${productTypes.CREATE_PRODUCT_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${productTypes.CREATE_PRODUCT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        image: null,
        product: action.payload,
      };
    case `${productTypes.CREATE_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        product: null,
      };

    case `${productTypes.UPDATE_PRODUCT_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${productTypes.UPDATE_PRODUCT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        image: null,
        product: action.payload,
      };
    case `${productTypes.UPDATE_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case `${productTypes.DELETE_PRODUCT_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${productTypes.DELETE_PRODUCT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${productTypes.DELETE_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        product: null,
      };

    case `${productTypes.UPLOAD_PRODUCT_IMAGE_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${productTypes.UPLOAD_PRODUCT_IMAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        image: action.payload,
      };
    case `${productTypes.UPLOAD_PRODUCT_IMAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case `${productTypes.HANDLE_PRODUCT_ADD_MODAL_VISIBLE}`:
      return {
        ...state,
        productModalVisible: !state.productModalVisible,
        record: action.payload,
      };

    case `${productTypes.HANDLE_DISTRICT_ADD_MODAL_VISIBLE}`:
      return {
        ...state,
        districtModalVisible: !state.districtModalVisible,
        record: action.payload,
      };

    case `${productTypes.UPDATE_BASKET}`:
      localStorage.setItem('basketItems', JSON.stringify(action.payload));
      return {
        ...state,
        basketItems: action.payload,
      };

    case `${productTypes.SET_BASKET_TOTAL}`:
      localStorage.setItem('total', JSON.stringify(action.payload));
      return {
        ...state,
        total: action.payload,
      };

    default:
      return state;
  }
};

export default productReducer;
