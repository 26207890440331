import React from 'react';
import { useDispatch } from 'react-redux';
import { offerAction } from 'redux/modules';
import InputMask from 'react-input-mask';
import { Col, Form, Input, Modal, Row, Button, message } from 'antd';

const FurnitureSelectPopUp = ({
  closeModal,
  visible,
  addToSelectedStuffs,
  offerFurnitures,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    form: { validateFields, getFieldDecorator },
  } = props;

  const offerData = {
    name: '',
    surName: '',
    email: '',
    phone: '',
    offerFurnitures,
  };
  return (
    <Modal
      title="İletişim Formu"
      centered
      visible={visible}
      onOk={() => {
        if (offerData) {
          dispatch(offerAction.create(offerData));
          closeModal();
        } else {
          message.error('Lütfen Doğru Giriş Yapın!');
        }
      }}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose={true}
    >
      <Form
        onSubmit={event => {
          event.preventDefault();
          validateFields((err, values) => {
            if (!err) {
              values = {
                ...values,
                phone: values.phone.split('-').join(''),
              };
              dispatch(offerAction.create({ ...values, offerFurnitures }));
            } else {
              message.error('Lütfen Verilerinizi Kontrol Edin.');
            }
          });
        }}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('fullName', {
                initialValue: offerData.name,
                rules: [{ required: true, message: 'Lütfen Ad ve Soyad Giriniz !' }],
              })(<Input placeholder="Ad" tooltiptitle="Adınız Soyadınız" />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('email', {
                initialValue: offerData.email,
                rules: [
                  { required: true, message: 'Lütfen E-mail Adresinizi Giriniz !' },
                  { type: 'email', message: 'E-mail adresi girmelisiniz !' },
                ],
              })(<Input placeholder="E-mail" tooltiptitle="E-mail adresiniz" />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('phone', {
                initialValue: offerData.phone,
                rules: [{ required: true, message: 'Lütfen Telefon Numaranızı Giriniz!' }],
              })(
                <InputMask mask="0(999)-999-9999">
                  {() => <Input placeholder="Telefon" tooltiptitle="Telefon Numaranız" />}
                </InputMask>,
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  display: 'block',
                  margin: 'auto',
                  backgroundColor: '#f27a1a',
                  color: '#fff',
                  border: 'none',
                }}
              >
                Gönder
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'offer' })(FurnitureSelectPopUp);
