export const LIST_PRODUCT_PENDING = 'LIST_PRODUCT_PENDING';
export const LIST_PRODUCT_FULFILLED = 'LIST_PRODUCT_FULFILLED';
export const LIST_PRODUCT_REJECTED = 'LIST_PRODUCT_REJECTED';

export const GET_PRODUCT_PENDING = 'GET_PRODUCT_PENDING';
export const GET_PRODUCT_FULFILLED = 'GET_PRODUCT_FULFILLED';
export const GET_PRODUCT_REJECTED = 'GET_PRODUCT_REJECTED';

export const CREATE_PRODUCT_PENDING = 'CREATE_PRODUCT_PENDING';
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED';
export const CREATE_PRODUCT_REJECTED = 'CREATE_PRODUCT_REJECTED';

export const UPDATE_PRODUCT_PENDING = 'UPDATE_PRODUCT_PENDING';
export const UPDATE_PRODUCT_FULFILLED = 'UPDATE_PRODUCT_FULFILLED';
export const UPDATE_PRODUCT_REJECTED = 'UPDATE_PRODUCT_REJECTED';

export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING';
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED';
export const DELETE_PRODUCT_REJECTED = 'DELETE_PRODUCT_REJECTED';

export const UPLOAD_PRODUCT_IMAGE_PENDING = 'UPLOAD_PRODUCT_IMAGE_PENDING';
export const UPLOAD_PRODUCT_IMAGE_FULFILLED = 'UPLOAD_PRODUCT_IMAGE_FULFILLED';
export const UPLOAD_PRODUCT_IMAGE_REJECTED = 'UPLOAD_PRODUCT_IMAGE_REJECTED';

export const HANDLE_PRODUCT_ADD_MODAL_VISIBLE = 'HANDLE_PRODUCT_ADD_MODAL_VISIBLE';
export const HANDLE_DISTRICT_ADD_MODAL_VISIBLE = 'HANDLE_DISTRICT_ADD_MODAL_VISIBLE';

export const UPDATE_BASKET = 'UPDATE_BASKET';
export const SET_BASKET_TOTAL = 'SET_BASKET_TOTAL';
