import React, { useEffect } from 'react';
import { ExternalScripts, FloatButton, Footer, Header } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from 'redux/modules';

const Stores = props => {
  const dispatch = useDispatch();
  const { products } = useSelector(state => state.product);
  const product = props?.location?.product != undefined ? props?.location?.product : products[0];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(productAction.getAll());
  }, []);

  return (
    <>
      <ExternalScripts />
      <div data-smooth-scroll-offset="77">
        <Header />
        <div className="main-container">
          <section className="imagebg cover cover-blocks bg--secondary info-bar" data-overlay="8">
            <div
              className="background-image-holder hidden-xs"
              style={{ background: "url('img/promo-1.jpg')", opacity: 1 }}
            >
              <img alt="background" src="img/promo-1.jpg" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div>
                    <br />
                    <br />
                    <h1 style={{ color: '#f27a1a' }}>{product?.name}</h1>
                    <p className="lead">{product?.description}</p>
                    <hr className="short" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="feature-large switchable bg--white custom-warehouse-info">
            <div className="container">
              <div className="row justify-content-around">
                <div className="col-lg-6 col-md-6">
                  <img
                    alt="Image"
                    className="border--round box-shadow-wide"
                    src={product?.imageUrl}
                  />
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="text-block">
                    <h5>İhtiyacınızı Belirleyin</h5>
                    <p>{product?.description}</p>
                  </div>
                  <div className="text-block">
                    <h5>Ölçüler</h5>
                    <ul>
                      <li>Genişlik : {Number(product?.width).toFixed(2)} m</li>
                      <li>Yükseklik : {Number(product?.height).toFixed(2)} m</li>
                      <li>Derinlik : {Number(product?.depth).toFixed(2)} m</li>
                      <br />
                      <li>
                        <h5>
                          Hacim :{' '}
                          {(
                            Number(product?.width) *
                            Number(product?.height) *
                            Number(product?.depth)
                          ).toFixed(2)}{' '}
                          m<sup>3</sup>
                        </h5>
                      </li>
                      <br />
                      <li>
                        <h4>Aylık {product?.price} TL + KDV</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="" style={{ marginTop: '-100px' }}>
            <div className="container">
              <div className="row text-block" style={{ textAlign: 'center' }}>
                <div className="col-md-12">
                  <p>
                    Tüm depolama süreçlerinizi online olarak tamamlamak için{' '}
                    <a href="/shop">buradan</a> devam edebilirsiniz. <br />
                    Whatsapp iletişim hattımız sorularınızı yanıtlamak için hizmetinizde 0537 475 97
                    33.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>

        <FloatButton />
      </div>
    </>
  );
};

export default Stores;
